import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { useNavigate,useParams} from "react-router-dom";
import { useState } from "react";
import { validateService} from "../service/validation.service";
import { apiService} from "../service/api.service";
import swal from "sweetalert";
import Loader from "../common/Loader";
import {PasswordRegex} from "../config/config"
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
const ResetPassword = () => {
  const { t } = useTranslation();
  let history = useNavigate();
  function Login() {
    history("/login");
  }
  let props = useParams()
  const [loader,setLoader]=useState(false)
  const [showPass,setshowPass]=useState(false)
  const [showPassConfirm,setshowPassConfirm]=useState(false)
  
  const [passwordFields, setpasswordFields] = useState({
    password: "",
    confirmPassword: "",
    token:"",
   });
   const [passwordFieldsErrs, setpasswordFieldsErrs] = useState({
    password: "",
    confirmPassword: "",
   });
   const handleSubmit = () => {
      
    // e.preventDefault();
   let formValidated =  validateService.forgotpasswordvalidate(setpasswordFieldsErrs,passwordFields)
   if(formValidated){
    setLoader(true)
    passwordFields.token = props.token
     console.log(passwordFields)
     apiService.resetPassword(passwordFields).then((res) => {
      setpasswordFields({
        password: "",
        confirmPassword: "",
        token:"",
       })
      swal(t("success"),t("pass_success"),"success")
      //  localStorage.clear()
      console.log(res)
      //  window.location.href = "/login"
      
       setLoader(false)
   }).catch((err) => {
    setLoader(false)
    console.log(err)
  swal("Error",err.response.data.message,"error")
   console.log("contact us api error", err.response);
   });
    
   }


   }
   const onInputChange = (field, value) => {
    if(field=="password"){
      checkPasswordStrngth(value)
        setpasswordFields({
          ...passwordFields,
          [field]: value,
        });
    
      
    }else{
    setpasswordFields({
      ...passwordFields,
      [field]: value,
    });
  }

  };
  const checkPasswordStrngth = (val)=>{
    if(PasswordRegex.test(val)){
      setpasswordFieldsErrs({
        ...passwordFieldsErrs,
        "password": "",
      });
      return true
  
    }else{
      setpasswordFieldsErrs({
        ...passwordFieldsErrs,
        "password": t("pass_regex")
      });
    }
    return false
  }



  return (
    <>
      <section className="login-section">
      {loader && <Loader/>}
        <Container>
          <Row>
            <Col lg={6} md={8}>
              <div className="login-form-box">
                <div className="login-header">
                  <h2>{t("create_pass")} </h2>
                  <p></p>
                </div>
                <Form className="login-body">
                  <Form.Group className="mb-3 position-relative">
                    <Form.Label>{t("password")}</Form.Label>
                    <Form.Control type={showPass?"text":"password"} placeholder={t("password_place")}  value={passwordFields.password} onChange={(e) => onInputChange("password", e.target.value)}/>
                    {showPass?
                    <img
                    className="eye-icon"
                    src={require("../assets/images/eye-crossed 1.svg").default}
                    onClick={()=>setshowPass(false)}
                    alt="img"
                  />:
                    <img
                      className="eye-icon"
                      onClick={()=>setshowPass(true)}
                      src={require("../assets/images/eye (1).svg").default}
                      alt="img"
                    />}
                    <span className="text-danger">{t(passwordFieldsErrs.password)}</span>
                  </Form.Group>
                  <Form.Group className="mb-3 position-relative" >
                    <Form.Label>{t("confirm_password")}</Form.Label>
                    <Form.Control type={showPassConfirm?"text":"password"} value={passwordFields.confirmPassword} placeholder={t("password_place")}  onChange={(e) => onInputChange("confirmPassword", e.target.value)}/>
                    {showPassConfirm?
                    <img
                    className="eye-icon"
                    src={require("../assets/images/eye-crossed 1.svg").default}
                    onClick={()=>setshowPassConfirm(false)}
                    alt="img"
                  />:
                    <img
                      className="eye-icon"
                      onClick={()=>setshowPassConfirm(true)}
                      src={require("../assets/images/eye (1).svg").default}
                      alt="img"
                    />}
                    <span className="text-danger">{t(passwordFieldsErrs.confirmPassword)}</span>
                  </Form.Group>
                  <Button onClick={handleSubmit} className="green-btn">{t("reset_pass")}</Button>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ResetPassword;
