import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import {timeRemaining} from "../../config/config"
const ProgressTimer = ({ createdAt,responseTime }) => {
  const totalDuration = parseFloat(responseTime) * 60 * 1000; // 5 minutes in milliseconds
  const [elapsedTime, setElapsedTime] = useState(0);
  const [timeLeft, settimeLeft] = useState();
  useEffect(() => {
    const createdAtTime =  moment.utc(createdAt).local()
    const updateElapsedTime = () => {
      const now = new Date()
      const currentTime =  moment.utc(now).local()
      const timeElapsed = currentTime - createdAtTime;
      if (timeElapsed >= totalDuration) {
        setElapsedTime(totalDuration);
      } else {
        setElapsedTime(timeElapsed);
      }
    };
    let time = timeRemaining(createdAt,responseTime)
    settimeLeft(time)
    const intervalId = setInterval(updateElapsedTime, 1000); // Update every second

    return () => clearInterval(intervalId); // Clean up on unmount
  }, [createdAt]);

  const progressPercentage = (elapsedTime / totalDuration) * 100;
  const remainingTime = totalDuration - elapsedTime;
  {console.log("====remainingTime===",remainingTime)}
  const formatTime = (milliseconds) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${minutes} min ${seconds}s left`;
  };
  return (
    <div class="progress">
      <div class={timeLeft<=2?"progress-bar two-minute-status":"progress-bar four-minute-ten-status"}>
       {formatTime(remainingTime)}
     </div>
    </div>
   
  );
};

ProgressTimer.propTypes = {
  createdAt: PropTypes.string.isRequired,
};

export default ProgressTimer;
