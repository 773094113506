import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    walletAddress: { accounts: [] } ,
    walletConnected: false ,
    profileImage: undefined ,
}

export const indexSlice = createSlice({
  name: 'indexslice',
  initialState,
  reducers: {
    setWalletAddress: (state, action) => {
        state.walletAddress = action.payload
    },
    setwalletConnected: (state, action) => {
        state.walletConnected = action.payload
    },
    setprofileImage: (state, action) => {
      state.profileImage = action.payload
  },
  },
})

// Action creators are generated for each case reducer function
export const { setWalletAddress,setwalletConnected,setprofileImage} = indexSlice.actions

export default indexSlice.reducer