import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { validateService } from "../service/validation.service";
import { apiService } from "../service/api.service";
import Loader from "../common/Loader";
import {PasswordRegex} from "../config/config"
import swal from "sweetalert";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
const SignUp = () => {
  const { t } = useTranslation();
  let history = useNavigate();
  function CreateProfile() {
    window.location.href = "/create-profile"
    // history("/create-profile");
  }
  const [loader, setLoader] = useState(false)
  const [showPass,setshowPass]=useState(false)
  const [showPassConfirm,setshowPassConfirm]=useState(false)
  const [registerFields, setregisterFields] = useState({
    email: "",
    password: "",
    confirmPassword: "",
    role: "cultivator",
    terms_and_conditions:false

  });
  const [registerFieldsErrs, setregisterFieldsErrs] = useState({
    email: "",
    password: "",
    cpassword: "",
    terms_and_conditions:""
  });
  const onInputChange = (field, value) => {
    if(field=="password"){
      checkPasswordStrngth(value)
        setregisterFields({
          ...registerFields,
          [field]: value,
        });
      
      
    }else{
      setregisterFields({
        ...registerFields,
        [field]: value,
      });
    }
   

  };
  const handleSubmit = () => {

    // e.preventDefault();

    let formValidated = validateService.register(setregisterFieldsErrs, registerFields)
    console.log(registerFieldsErrs)
    if (formValidated) {
      setLoader(true)
      apiService.signup(registerFields).then((res) => {
        setregisterFields({
          email: "",
          password: "",
          confirmPassword: "",
          role: "cultivator",
        });
        setregisterFieldsErrs({
          email: "",
          emailErr: "",
          password: "",
          passwordErr: "",
        });
        setLoader(false)
        console.log(res.data)
        localStorage.setItem("web_token", res.data.data.token)
        localStorage.setItem("user", res.data.data.user.id)

        CreateProfile()
      })
        .catch((err) => {
          swal("Error",err.response.data.message,"error")
          // if(err.response.data.message=="Please Verify your email first"){

          // }   setLoader(true)
          console.log(err)

          setLoader(false)

          console.log("contact us api error", err.response);
        });
    }


  }
  const checkPasswordStrngth = (val)=>{
    if(PasswordRegex.test(val)){
      setregisterFieldsErrs({
        ...registerFieldsErrs,
        "password": "",
      });
      return true

    }else{
      setregisterFieldsErrs({
        ...registerFieldsErrs,
        "password": "pass_regex",
      });
    }
    return false
  }

  return (
    <>
      <section className="login-section">
        {loader && <Loader />}
        <Container>
          <Row>
            <Col lg={6} md={8}>
              <div className="login-form-box">
                <div className="login-header">
                  <h2>{t("signup")}</h2>
                  <p>{t("use_email")}</p>
                </div>
                <Form className="login-body">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("email_place")}</Form.Label>
                    <Form.Control type="email" placeholder={t("enter_email")}
                      onChange={(e) => onInputChange("email", e.target.value)}

                    />
                    <span className="text-danger">{t(registerFieldsErrs.email)}</span>
                  </Form.Group>
                  <Form.Group className="mb-3 position-relative">
                    <Form.Label>{t("password")}</Form.Label>
                    <Form.Control type={showPass?"text":"password"} placeholder={t("password_place")}
                      onChange={(e) => onInputChange("password", e.target.value)}

                     
                    />
                   {showPass?
                    <img
                    className="eye-icon"
                    src={require("../assets/images/eye-crossed 1.svg").default}
                    onClick={()=>setshowPass(false)}
                    alt="img"
                  /> :
                    <img
                      className="eye-icon"
                      onClick={()=>setshowPass(true)}
                      src={require("../assets/images/eye (1).svg").default}
                      alt="img"
                    />
                    }
                    <span className="text-danger">{t(registerFieldsErrs.password)}</span>
                  </Form.Group>
                  <Form.Group className="mb-3 position-relative">
                    <Form.Label>{t("confirm_password")}</Form.Label>
                    <Form.Control type={showPassConfirm?"text":"password"} placeholder={t("enter_pass_confirm")}
                      onChange={(e) => onInputChange("confirmPassword", e.target.value)}
                    />
                      {showPassConfirm?
                    <img
                    className="eye-icon"
                    src={require("../assets/images/eye-crossed 1.svg").default}
                    onClick={()=>setshowPassConfirm(false)}
                    alt="img"
                  />:
                    <img
                      className="eye-icon"
                      onClick={()=>setshowPassConfirm(true)}
                      src={require("../assets/images/eye (1).svg").default}
                      alt="img"
                    />}
                    <span className="text-danger">{t(registerFieldsErrs.cpassword)}</span>
                  </Form.Group>
                  <Form.Group className="mb-4" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label={
                      <>
                        <span>{t("accpet")} <a className="terms-condition" href="/terms-condition" target="_blank">{t("t&c")}</a></span>
                      </>
                    }
                      onChange={(e) => {
                        setregisterFields((prevObj) => {
                          return {
                            ...prevObj,
                            terms_and_conditions: e.target.checked
                          };
                        });
                      }}
                    />
                   <span className="text-danger">{t(registerFieldsErrs.term)}</span>
                  </Form.Group>
                  <Button onClick={handleSubmit} className="green-btn">{t("signup")}</Button>
                  <p>{t("already_account")} <Link to="/login" className="">{t("sign_in")}</Link></p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default SignUp;
