import { useState, useEffect } from 'react';
import { Button, Col, Container, Form, Modal, Row } from "react-bootstrap";
import TabComponent from "../../../common/TabComponent";
import { Link } from 'react-router-dom';
import { useParams } from "react-router-dom";
import { baseUrl, errorHandling, getStatus, socketUrl, apiUrl,formatToColombianCurrency,timeRemaining } from "../../../config/config"
import { apiService } from "../../../service/api.service";
import swal from 'sweetalert';
import OtpInput from 'react-otp-input';
import moment from "moment";
import ProgressTimer from "../progressTimer"
import ReactStars from "react-rating-stars-component";
import Loader from "../../../common/Loader";
import useWebSocket from 'react-use-websocket';
import { Stomp } from '@stomp/stompjs';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import '../../../i18n';
const OrderDetails = () => {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
 
    let props = useParams()
    const [loader, setLoader] = useState(false)
    const [orderData, setorderData] = useState()
    const [orderReviews, setorderReview] = useState(null)
    const [orderHis, setorderHis] = useState()
    const [orderStatus, setorderStatus] = useState()
    const [otp, setOtp] = useState()
    const [otpErr, setOtpErr] = useState()
    const [timeLeft, settimeLeft] = useState()
    const [responseTime, setresponseTime] = useState();
    const [ratingStar, setratingStar] = useState({
        size: 30,
        value: 0,
        edit: false,
        isHalf: true,
        emptyIcon: <i className="far fa-star" />,
        halfIcon: <i className="fa fa-star-half-alt" />,
        filledIcon: <i className="fa fa-star" />,
    });
    // props.orderID
    useEffect(() => {
     
        getOrderDetail(props.orderId, false)
        orderReview(props.orderId)
        orderHistory(props.orderId)

    }, [])

    const getOrderDetail = (id, socket) => {
        if (!socket) {
            setLoader(true)
        }

        apiService.getOrderDetail(id).then((res) => {
            setorderData(res.data.data)
            setresponseTime(res.data.data.responseTime)
            let time = timeRemaining(res.data.data.order.orderDate,res.data.data.responseTime)
            console.log("====timeleft====",time)
            settimeLeft(time)
            setorderStatus(getStatus(res.data.data.order.status))
            console.log("order====", res.data.data)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            errorHandling(err.response?.status)

            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }

    const orderReview = (id) => {
        apiService.orderReview(id).then((res) => {
            setratingStar({
                ...ratingStar,
                value: res.data.data.cultivatorReview.rating,
            });
            setorderReview(res.data.data.cultivatorReview)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            //  errorHandling(err.response.status)

            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }
    const orderHistory = (id) => {
        apiService.orderHistory(id).then((res) => {
            console.log("orderHis====", res.data)
            setorderHis(res.data.data.list)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            //  errorHandling(err.response.status)

            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }

    const acceptRejectOrder = (status) => {
        let params = {
            orderId: props.orderId,
            status: status
        }
        setLoader(true)
        apiService.acceptRejectOrder(params).then((res) => {
            if (status == "Accept") {
                swal(t("success"), t("order_accpet"), "success")
            } else {
                swal(t("success"), t("order_reject"), "success")
            }

            getOrderDetail(props.orderId, false)
            orderHistory(props.orderId)
            console.log("order====", res.data)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            swal("Error", err.response.data.message, "error")
            errorHandling(err.response.status)

            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }
    const searchDriver = () => {

        setLoader(true)
        apiService.searchDriver(props.orderId).then((res) => {
            //   swal("Success","Order "+status+"ed !","success")
            getOrderDetail(props.orderId, false)
            console.log("order====", res.data)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            errorHandling(err.response.status)

            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }
    const orderPickup = () => {
        if (!otp) {
            setOtpErr(t("enter_otp"))
            return
        }
        setLoader(true)
        apiService.orderPickup(props.orderId, otp).then((res) => {
            setOtp()
            setOtpErr()
            getOrderDetail(props.orderId, false)
            handleClose()
            setLoader(false)
        }).catch((err) => {
            setOtp()
            setOtpErr(err.response.data.message)
            setLoader(false)
            errorHandling(err.response.status)
            console.log("contact us api error", err.response);
        });
    }



    const getTimeAgo = (dateTime) => {

        dateTime = moment.utc(dateTime).local()
        return moment(dateTime).fromNow();
    };
    const getdateTime = (dateTime) => {
        // moment.locale(localStorage.getItem("lang"));
        dateTime = moment.utc(dateTime).local()
        return moment(dateTime).format("DD MMMM, YYYY hh:mm A");
    };
    const handleDownload = async () => {
        console.log("handled download")
        let apiEndpoint = `${apiUrl}/user-order/pdf/${props.orderId}`
        let access_token = localStorage.getItem("web_token")
        try {
            const response = await fetch(apiEndpoint, {
                method: 'GET',
                headers: {

                    'appVersion': '1.0',
                    'Authorization': 'Bearer ' + access_token,
                    "role": "CULTIVATOR",
                    "Accept-Language": localStorage.getItem("lang")

                },
            });

            const pdfBlob = await response.arrayBuffer();
            const blob = new Blob([pdfBlob], { type: 'application/pdf' });

            // Create an <a> element
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = 'Order Invoice-' + props.orderId + '.pdf'; // Specify filename for the downloaded file

            // Programmatically trigger the download
            document.body.appendChild(link);
            link.click();

            // Clean up
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href);
            // setTimeout(() => {
            //   window.location.reload()
            // }, 10)
        } catch (error) {
            // console.error('Error:', error.message);
        }
    }
    useEffect(() => {
        // Set up WebSocket connection
        const client = Stomp.client(socketUrl);
        //
        client.connect({}, () => {
            // /subscribe/user/{identifier}'
            client.subscribe('/subscribe/user/' + localStorage.getItem("user"), (message) => {
                setorderStatus(getStatus(message.body.status))
                getOrderDetail(props.orderId, true)
                orderReview(props.orderId)
                orderHistory(props.orderId)
            });

            client.onStompError = (error) => {
                console.error('STOMP Error:', error);
            };

            // Optional: Add debug logging
            client.debug = (msg) => {
                console.log('STOMP Debug:', msg);
            };

            // Clean up function
            return () => {
                client.disconnect();
            };
        })


    }, []);


    return (<>
        <div className="height-footer">
            {loader && <Loader />}
            <Container>
                <TabComponent />
                <div className="tab-pane" id="project-management">
                    <div className='project-management common-under-padding'>
                        <div className='product-header-area'>
                            <h2 className='subheading-under'>{t("order_details")}</h2>
                            <div className='under-heading'>
                                {/* <div className='search-box position-relative status-bar'>
                                    <img
                                        className="search-img"
                                        src={require("../../../assets/images/search.svg").default}
                                        alt="img"
                                    />
                                    <Form.Control type="input" placeholder="Search by name" />
                                    <Form.Select aria-label="Default select example">
                                        <option>Select</option>
                                        <option value="1">Delivered</option>
                                        <option value="2">Pending</option>
                                        <option value="3">Pending</option>
                                    </Form.Select>
                                </div> */}
                            </div>
                        </div>
                        <div className="stock-shadow">
                            <div className="order-history-header">
                                <p className="date-text">
                                    <img
                                        className=""
                                        src={require("../../../assets/images/date.svg").default}
                                        alt="img"
                                    />
                                    {moment(orderData?.order.orderDate).format("DD MMMM, YYYY")}
                                </p>

                                
                            </div>
                            <Row>
                                <Col lg={9} className='mb-3'>
                                    <div className="customer-detailsl-box">
                                        <p className="customer-txt">{t("customer")}</p>
                                        <div className="customer-profile customer-profile-view">
                                            <div className="customer-profile-img">
                                                {orderData?.order.customer?.imagePath == null ?
                                                    <img
                                                        className=""
                                                        src={require("../../../assets/images/user-default.png")}
                                                        alt="img"
                                                    /> :
                                                    <img
                                                        className=""
                                                        src={baseUrl + orderData?.order.customer?.imagePath}
                                                        alt="img"
                                                    />}
                                                <div>
                                                    <p className="customer-name">{orderData?.order.customer?.firstName + " " + orderData?.order.customer?.lastName}</p>
                                                    <p className="customer-id">{t("cust_id")} #{orderData?.order.customer?.id}</p>
                                                </div>
                                            </div>
                                            <div className="status-txt">
                                                {orderStatus == "Order Cancelled" ?
                                                    <>
                                                        <span className="delivered pending"></span>
                                                        <h4>{orderStatus ? t("cancelled") : ""}</h4></> :
                                                    <>{orderStatus == "Pending" ? <>
                                                            <span className="delivered pending"></span>
                                                            <h4>{orderStatus ? t("pending") : ""}</h4></> :
                                                            <>{orderStatus == "Order Delivered" ? <>
                                                                    <span className="delivered"></span>
                                                                    <h4>{orderStatus ? t("delivered") : ""}</h4></> : <>
                                                                        {orderStatus == "Driver Not Assigned" ?
                                                                            <> <span className="delivered not-assigned"></span>
                                                                                {orderStatus ? t("driver_not_assigned") : ""}</>
                                                                            : <>{orderStatus == "Driver Assigned" ?
                                                                                    <> <span className="delivered driver-assigned"></span>
                                                                                        {orderStatus ? t("driver_assigned") : ""}</> :
                                                                                   <>{orderStatus == "Rejected" ?
                                                                                        <> <span className="delivered pending"></span>
                                                                                            {orderStatus ? t("rejected") : ""}</> :
                                                                                        <><span className="delivered accepted"></span>
                                                                                            <h4>{orderStatus ? orderStatus : ""}</h4></>} </>

                                                                                }


                                                                            </>


                                                                        }
                                                                    </>

                                                                }
                                                            </>}
                                                    </>}



                                                {/* pending */}
                                                {/* <span className="delivered pending"></span>
                                                <h4>Pending</h4> */}
                                                {/* order accepted */}
                                                {/* <span className="delivered accepted"></span>
                                                <h4>Order Accepted</h4> */}
                                                {/* order assigned */}
                                                {/* <span className="delivered accepted"></span>
                                                <h4>Driver Assigned </h4> */}
                                                {/* out for delivery */}
                                                {/* <span className="delivered"></span>
                                                <h4>Out for Delivery</h4> */}
                                            </div>
                                        </div>
                                        <Row>
                                            <Col md={4} lg={4}>
                                                <div className="customer-info">
                                                    <p className="customer-id">{t("phone")}</p>
                                                    <p>{orderData?.order.customer?.phone}</p>
                                                </div>
                                            </Col>
                                            {/* <Col md={4} lg={4}>
                                                <div className="customer-info">
                                                    <p className="customer-id">Email</p>
                                                    <p>{orderData?.orderDetail.customer.email ? orderData?.orderDetail.customer.email : "N/A"}</p>
                                                </div>
                                            </Col> */}
                                            <Col md={4} lg={4}>
                                                <div className="customer-info">
                                                    <p className="customer-id">{t("address")}</p>
                                                    <p>{orderData?.order.deliveryAddress?.address}</p>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col lg={3} className='mb-3'>
                                    {orderData?.order.driver &&
                                        <div className="customer-detailsl-box">
                                            <div className='action-outer'>
                                                <p className="customer-txt">{t("driver_info")}</p>
                                                {orderStatus !== "Order Delivered" &&
                                                    // <a href ="https://www.google.com/maps" target='_blank'><Button className="green-btn radius-btn" >track order</Button></a>
                                                    <a href={`/order-mangement/track-order/${props.orderId}`} target='_blank'><Button className="green-btn radius-btn" >{t("track")}</Button></a>

                                                }
                                            </div>
                                            <div className="customer-profile customer-profile-delivery-box">
                                                <div className="customer-profile-img">
                                                    <div>
                                                        {orderData?.order.driver?.imagePath != null ?
                                                            <img
                                                                className=""
                                                                src={baseUrl + orderData?.order.driver?.imagePath}
                                                                alt="img"
                                                            /> : <img
                                                                className=""
                                                                src={require("../../../assets/images/user-default.png")}
                                                                alt="img"
                                                            />}

                                                    </div>
                                                    <div>
                                                        <p className="customer-name">{orderData?.order.driver?.firstName + " " + orderData?.order.driver?.lastName}
                                                        </p>
                                                        <p className="customer-id">{orderData?.order.driver?.phone}</p>
                                                        <p className="customer-id mt-1">ID {orderData?.order.driver?.id}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>}
                                </Col>
                            </Row>
                            <div className='order-section'>
                                <h5 className='latest-order-txt'>{t("order_list")}</h5>
                                <div className='table-data'>
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>{t("orderid")}</th>
                                                <th>{t("product_name")}</th>
                                                <th>{t("packaging")}</th>
                                                <th>{t("qty")}</th>
                                                <th>{t("amount")}</th>
                                                <th>{t("total")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {orderData && orderData.order.products.map((data, index) => {
                                                return (
                                                    <tr>
                                                        <td>#{props.orderId}</td>
                                                        <td>
                                                            <div className='product-outer-main'>
                                                                {data.productImagePath != null &&
                                                                    <span className='product-img'>
                                                                        <img
                                                                            className=""
                                                                            src={baseUrl + data.productImagePath}
                                                                            // src={require("../../../assets/images/mango.png")}
                                                                            alt="img"
                                                                        />
                                                                    </span>}
                                                                {data.productName}
                                                            </div>
                                                        </td>
                                                        <td>{data.packaging + " " + data.unit}  </td>
                                                        <td>{data.productTotalQuantity} {t("item")} </td>
                                                        <td>${formatToColombianCurrency(data.productPrice)}</td>
                                                        <td>${formatToColombianCurrency(data.productTotal)}</td>
                                                    </tr>
                                                )
                                            })}



                                        </tbody>
                                    </table>
                                </div>
                                <div className="shipping-box">



                                    <div className="text-right">
                                        <div className="shipping-box-inner">
                                            <p>{t("order_amount")}:</p>
                                            <p>${formatToColombianCurrency(orderData?.order.totalAmount)}</p>
                                        </div>
                                        <div className="shipping-box-inner">
                                            <p>{t("gross_amt")}:</p>
                                            <p>${formatToColombianCurrency(orderData?.commission.cultivatorGross)}</p>
                                        </div>
                                        {/* <div className="shipping-box-inner">
                                            <p>Delivery Charge:</p>
                                            <p className='red-text'><span >-</span> ${orderData?.commission.deliveryFee}</p>
                                        </div> */}
                                        <div className="shipping-box-inner">
                                            <p>{t("platform_fee")}:</p>
                                            <p className='red-text'><span >-</span> ${formatToColombianCurrency(orderData?.commission.cultivatorCommission)}</p>
                                        </div>

                                        <div className="shipping-box-inner">
                                            <p className="text-dark">{t("total_earn")}:</p>
                                            <p className="text-dark">${formatToColombianCurrency(orderData?.commission.cultivatorPayable)}</p>
                                        </div>
                                        {/* <p className="payment-status">Payment Done</p> */}
                                    </div>
                                </div>
                            </div>

                        </div>

                        {(orderData?.order.status == "ORDER_INITIATED" || orderData?.order.status == "AWAITING_PAYMENT") && timeLeft >0  ?
                        <div>
                            <div className="action-btn-add mt-4">
                                {/* {orderData?.order.status} */}

                                <Button className="green-btn radius-btn cancel-btn-outline reject-button"
                                    onClick={(e) =>
                                        swal({
                                            title: t("confirm"),
                                            text: t("reject_confirm"),
                                            icon: "warning",
                                            buttons: [t("no"), t("yes")],
                                        })
                                            .then((value) => {
                                                if (value) {

                                                    acceptRejectOrder("Reject")


                                                } else {
                                                    console.log("NO button clicked or popup closed");
                                                }
                                            })

                                    }
                                >{t("reject_order")}</Button>
                                <Button className="green-btn radius-btn" onClick={(e) =>
                                    swal({
                                        title: t("confirm"),
                                        text: t("accept_confirm"),
                                        icon: "warning",
                                        buttons: [t("no"), t("yes")],
                                    })
                                        .then((value) => {
                                            if (value) {

                                                acceptRejectOrder("Accept")


                                            } else {
                                                console.log("NO button clicked or popup closed");
                                            }
                                        })

                                }>{t('accept')}</Button>

                            </div>
                            <div className="position-relative outer-position-relative">
                            {orderStatus !== "Pending" &&
                                <Button className="export-btn" onClick={handleDownload}>{t("export_pdf")}</Button>}
                            {/*                                     
                            <img
                                className="down-arrow"
                                src={require("../../../assets/images/down.svg").default}
                                alt="img"
                            /> */}
                            {timeLeft >0 &&
                            <div className='outer-progress-bar'>
                            <ProgressTimer createdAt={orderData?.order.orderDate} responseTime={responseTime}/>
                            </div>}
                        </div>
                        </div>
                             : null
                        }
                        {/* {orderData?.order.status} */}
                        {(orderData?.order.status == "SEARCHING_DELIVERY_PARTNER" || orderData?.order.status == "PICKUP_CONFIRMATION_PENDING") && orderData?.order.scheduleCount < 5 &&
                            <div className="action-btn-add mt-4">

                                {/* <Button className="green-btn radius-btn cancel-btn-outline">Reject Order</Button> */}
                                <Button className="green-btn radius-btn search-near-driver" > <span className='loader-search'></span>{t("search")}</Button>
                            </div>}

                        {(orderData?.order.status == "SEARCHING_DELIVERY_PARTNER" || orderData?.order.status == "PICKUP_REJECTED" || orderData?.order.status == "PICKUP_CONFIRMATION_PENDING") && orderData?.order.scheduleCount >= 5 &&
                            <div className="action-btn-add mt-4">

                                {/* <Button className="green-btn radius-btn cancel-btn-outline">Reject Order</Button> */}
                                <Button className="green-btn radius-btn" onClick={searchDriver}>{t("search")}</Button>
                            </div>
                        }
                        {orderData?.order.status == "PICKUP_CONFIRM" &&
                            <div className="action-btn-add mt-4">

                                {/* <Button className="green-btn radius-btn cancel-btn-outline">Reject Order</Button> */}
                                <Button className="green-btn radius-btn" onClick={handleShow}>{t("pickup")}</Button>
                            </div>
                        }
                        {orderData?.order.status == "ORDER_DELIVERED" &&
                            <div className="action-btn-add mt-4">

                                {/* <Button className="green-btn radius-btn cancel-btn-outline">Reject Order</Button> */}
                                <a className=" delivered-status"><i className="fa-solid fa-check me-2"></i> {t("delivered")}</a>
                            </div>
                        }
                        {orderData?.order.status == "PICKUP_PARTNER_VERIFIED" &&
                            <div className="action-btn-add mt-4">

                                {/* <Button className="green-btn radius-btn cancel-btn-outline">Reject Order</Button> */}
                                <Button className="green-btn radius-btn" >{t("out_delivery")}</Button>
                            </div>
                        }


                        {orderData?.order.status == "ORDER_REJECTED" &&
                            <div className="action-btn-add mt-4">

                                <Button className="cancel-btn-outline rejected-status"><i className="fa-solid fa-xmark"></i> {t("rejected")}</Button>
                                {/* <Button className="green-btn radius-btn" onClick={handleShow}>Ready To Pickup</Button> */}
                            </div>
                        }
                        {orderData?.order.status == "ORDER_CANCELLED" &&
                            <div className="action-btn-add mt-4">
                                <Button className=" cancel-btn-outline order-cancelled"><i className="fa-solid fa-xmark"></i> {t("order_cancelled")}</Button>
                                {/* <Button className="green-btn radius-btn" onClick={handleShow}>Ready To Pickup</Button> */}
                            </div>
                        }
                        {orderData?.order.status == "AT_DELIVERY_LOCATION" &&
                            <div className="action-btn-add mt-4">

                                <Button className="green-btn radius-btn">{t("driver_arr")}</Button>
                                {/* <Button className="green-btn radius-btn" onClick={handleShow}>Ready To Pickup</Button> */}
                            </div>
                        }

                    </div>
                    {orderData?.order.status !== "ORDER_INITIATED" &&
                        <>
                            <div className='product-header-area'>
                                <h2 className='subheading-under'>{t("history")}</h2>
                            </div>
                            <Row>
                                <Col lg={8}>
                                    {orderHis && orderHis.length > 0 ?
                                        <div className="hostory-box">
                                            {orderHis && orderHis.map((data, index) => {
                                                return (<p><span className='outer-main-span'><span className="number-box"><i className="fa-solid fa-circle"></i></span>
                                                    <span className="text-box">{data.description}</span></span><span className='dateTime'>({getdateTime(data.createdDate)})</span></p>)

                                            })}


                                        </div> :
                                        <div className="hostory-box">
                                            <div className="nodata centre">
                                                <img className="" src={require("../../../assets/images/no-data-found.png")} alt="img"
                                                />
                                                <span>{t("no_data_found")}</span>
                                            </div>
                                            {/* <p> <span className="number-box"></span><span className="text-box">{t("no_data_found")}</span></p>  */}
                                        </div>
                                    }
                                </Col>
                                <Col lg={4}>
                                    <h5 className='latest-order-txt mb-3'>{t("cust_feed")}</h5>


                                    <div className="customer-box">
                                        {orderReviews != null ?
                                            <div className="customer-feedback">
                                                <p className="customer-id text-right">{getTimeAgo(orderReviews?.createdDate)} </p>
                                                <div className="customer-fedback-img">
                                                    <div>
                                                        {orderReviews?.user.imagePath == null ?

                                                            <img
                                                                className=""
                                                                src={require("../../../assets/images/user-default.png")}
                                                                alt="img"
                                                            /> :
                                                            <img
                                                                className=""
                                                                src={baseUrl + orderReviews?.user.imagePath}
                                                                alt="img"
                                                            />}
                                                    </div>
                                                    <div>
                                                        <p className="ratings mb-3 mt-0">
                                                            <span className="star-pattern">
                                                                {console.log(ratingStar)}
                                                                <ReactStars {...ratingStar} />  <span className='text-star'>({orderReviews?.rating})</span></span></p>
                                                        <p className="customer-id mt-1">{orderReviews?.description}</p>
                                                    </div>
                                                </div>
                                            </div> :
                                            <div className="customer-feedback">

                                                <div className='no-feedback'>
                                                    <img
                                                        className="default-profile-img"
                                                        src={require("../../../assets/images/feedback.png")}
                                                        alt="img"
                                                    />
                                                    <span>{t("no_feed")}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Col>
                            </Row>
                        </>}
                </div>
            </Container>
        </div>
        {/* success modal */}
        <Modal show={show} onHide={handleClose} className='modal-success' backdrop="static" centered>
            <Modal.Header closeButton className='border-0'>
                {/* <Modal.Title>Modal heading</Modal.Title> */}
            </Modal.Header>
            <Modal.Body >
                <div className='modal-success-body text-center'>
                    <h3 className='otp-text'>{t("otp_ver")}</h3>
                    <Form className="login-body">
                        <Form.Label>{t("enter_otp")}</Form.Label>


                        <div className='otp-box'>
                            <OtpInput
                                className="form-control"
                                value={otp}
                                onChange={setOtp}
                                numInputs={4}
                                renderSeparator={<span> </span>}
                                renderInput={(props) => <input {...props} />}
                            />
                       </div>
                        <span className='errors'>{otpErr}</span>
                      <Button className="green-btn" onClick={orderPickup}>{t("accept")}</Button>

                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    </>)
}

export default OrderDetails;