import Noheader from "./Noheader";
const MobileLayout = (props) => {
  return (
    <>
    <Noheader/>
      {props.children}
 
    </>
  );
};

export default MobileLayout;
