import { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { apiService } from "../service/api.service";
import {
  baseUrl,
  allowedImageTypes,
  PasswordRegex,
  errorHandling,
} from "../config/config";
import Loader from "../common/Loader";
import { validateService } from "../service/validation.service";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setprofileImage } from "../store/index_slice";
import swal from "sweetalert";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
const MyProfile = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const [showPass, setshowPass] = useState(false);
  const [showPassOld, setshowPassOld] = useState(false);
  const [showPassConfirm, setshowPassConfirm] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loader, setLoader] = useState(false);
  const [image, setImage] = useState();
  const [imageShow, setimageShow] = useState();
  const [imageErr, setImageErr] = useState();
  const [profileData, setprofileData] = useState();
  const selectedlanguage = localStorage.getItem("lang")
  const [changePasswordFields, setchangePasswordFields] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });

  const [changePasswordFieldsErr, setchangePasswordFieldsErr] = useState({
    oldPassword: "",
    password: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();
  useEffect(() => {
    GetProfile();
  }, []);
  let history = useNavigate();
  function EditProfile() {
    history("/update-profile");
  }
  const GetProfile = () => {
    setLoader(true);
    apiService
      .GetProfile()
      .then((res) => {
        setLoader(false);
        setprofileData(res.data.data.user);
        localStorage.setItem("profile_status", res.data.data.user.status);
        localStorage.setItem(
          "stripeCustomerId",
          res.data.data.user.stripeCustomerId
        );
      })
      .catch((err) => {
        errorHandling(err.response.status);
        // if (err.response.status === 401) {
        //   swal({ icon: 'error', text: "Your session has been expired, please login again. ", button: "OK" }).then(() => {
        //     localStorage.clear()
        //     window.location.href = "/login"
        //   });

        // }
        setLoader(false);
        console.log(err);
        console.log("contact us api error", err.response);
      });
  };
  const uplodFile = (imagedata) => {
    setLoader(true);
    let datanew = new FormData();
    datanew.append("file", imagedata);
    apiService
      .uplodFile("image", datanew)
      .then((res) => {
        dispatch(setprofileImage(baseUrl + res.data.data.user.imagePath));
        localStorage.setItem(
          "profile_img",
          baseUrl + res.data.data.user.imagePath
        );
        GetProfile();
        setTimeout(() => {
          setLoader(false);
        }, 2000);
      })
      .catch((err) => {
        setLoader(false);
        console.log(err);
        console.log("contact us api error", err.response);
      });
  };
  const changePassword = () => {
    let valid = validateService.resetpasswordvalidate(
      setchangePasswordFieldsErr,
      changePasswordFields
    );
    if (valid) {
      setLoader(true);
      apiService
        .changePassword(changePasswordFields)
        .then((res) => {
          setLoader(false);
          swal({
            title: t("success"),
            text: t("pass_changed"),
            icon: "success",
            buttons: t("ok"),
          }).then((confirmed) => {

            localStorage.clear();
            localStorage.setItem("lang",selectedlanguage)
            window.location.href = "/login";
          });

          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          swal(t("error"), err.response.data.message, "error");
          console.log("contact us api error", err.response);
        });
    }
  };

  const deleteAccount = () => {
 
      setLoader(true);
      apiService.accountDelete()
        .then((res) => {
          setLoader(false);
          localStorage.clear();
          localStorage.setItem("lang",selectedlanguage)
          window.location.href = "/login";
          // swal({
          //   title: t("success"),
          //   text: t("pass_changed"),
          //   icon: "success",
          //   buttons: t("ok"),
          // }).then((confirmed) => {

           
          // });

          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          console.log(err);
          swal(t("error"), err.response.data.message, "error");
          console.log("contact us api error", err.response);
        });
    
  };
  const handleImageChange = (file) => {
    const selectedImage = file;
    let valid = true;
    if (selectedImage) {
      console.log("=====");

      if (allowedImageTypes.includes(selectedImage.type)) {
        setImage(selectedImage);
        uplodFile(selectedImage);
        valid = true;
        const reader = new FileReader();
        reader.onload = () => {
          setimageShow(reader.result);
        };
        reader.readAsDataURL(selectedImage);
        setImageErr("");
      } else {
        valid = false;
        console.log(
          "Invalid file type. Please select a JPEG,JPG or PNG image."
        );
        setImageErr(
          "Invalid file type. Please select a JPEG,JPG or PNG image."
        );
      }
    }
    return valid;
  };
  const onInputChange = (field, value) => {
    if (field == "password") {
      checkPasswordStrngth(value);
      setchangePasswordFields({
        ...changePasswordFields,
        [field]: value,
      });
    } else {
      setchangePasswordFields({
        ...changePasswordFields,
        [field]: value,
      });
    }
  };
  const checkPasswordStrngth = (val) => {
    if (PasswordRegex.test(val)) {
      setchangePasswordFieldsErr({
        ...changePasswordFieldsErr,
        password: "",
      });
      return true;
    } else {
      setchangePasswordFieldsErr({
        ...changePasswordFieldsErr,
        password:
          t("pass_regex"),
      });
    }
    return false;
  };
  return (
    <>
      <div className="height-footer">
        {loader && <Loader />}
        <Container>
          <div className="profile-outer-box">
            <div className="product-header-area">
              <h2 className="subheading-under">{t("my-profile")}</h2>
              <div className="under-heading">
                <Button className="green-btn" onClick={handleShow}>
                  {t("changepass")}
                </Button>
                <Button
                  className="green-btn green-outline-btn"
                  onClick={() => EditProfile()}
                >
                  {t("edit_pass")}
         
                </Button>
                <Button
                  className="red-btn red-outline-btn"
                  onClick={()=>{
                    swal({
                      title: t("confirm"),
                      text: t('del_acc'),
                      icon: 'warning',
                      buttons: [t('cancel'), t('yes')],
                      dangerMode: true,
                    }).then((confirmed) => {
                      if (confirmed) {

                        deleteAccount()
                        
                      }
                    })
                   }}
                  // onClick={() => EditProfile()}
                >
                  <i class="fa fa-trash" aria-hidden="true"></i>
                  
                  
                  {"  "+t("delete_acc")}
         
                </Button>
              </div>
            </div>
            <div className="under-profile">
              <div className="profile-img">
                {profileData?.imagePath ? (
                  <img
                    className="my-profile-img"
                    src={
                      imageShow ? imageShow : baseUrl + profileData?.imagePath
                    }
                    alt="img"
                  />
                ) : (
                  <img
                    className="my-profile-img"
                    src={require("../assets/images/user-default.png")}
                    alt="img"
                  />
                )}
                <Form.Control
                  type="file"
                  onChange={(e) => handleImageChange(e.target.files[0])}
                />
                <img
                  className="edit-icon"
                  src={require("../assets/images/edit-icon.svg").default}
                  alt="img"
                />
                <span className="text-danger">{imageErr}</span>
              </div>
              <div className="review-box">
                {profileData?.cultivator && profileData?.cultivator?.totalReview >0 &&
                <Link to="/reviews" className="text-decoration-one">
                  <p className="review-txt">
                    <span className="star-pattern">
                      <i className="fa-solid fa-star"></i>
                    </span>
                   <span className="green-txt text-decoration-one">
                      {profileData?.cultivator?.rating}
                    </span>{" "} 
                    ({profileData?.cultivator?.totalReview} {profileData?.cultivator?.totalReview==1?t("review"):t("reviews")})
                  </p>
                </Link>}
               </div>
              <Row>
                <Col sm={6} md={4} lg={4}>
                  <div className="customer-info">
                    <p className="customer-id">{t("first_name")}</p>
                    <p className="profile-details">
                      {profileData?.firstName ? profileData?.firstName : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <div className="customer-info">
                    <p className="customer-id">{t("last_name")}</p>
                    <p className="profile-details">
                      {profileData?.lastName ? profileData?.lastName : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <div className="customer-info">
                    <p className="customer-id">{t("email_place")}</p>
                    <p className="profile-details">
                      {profileData?.email ? profileData?.email : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <div className="customer-info">
                    <p className="customer-id">{t("phone_number")}</p>
                    <p className="profile-details">
                      {profileData?.phone ?  profileData?.phone : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <div className="customer-info">
                    <p className="customer-id">{t("gender")}</p>
                    <p className="profile-details">
                      {profileData?.gender ? profileData?.gender : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <div className="customer-info">
                    <p className="customer-id">{t("business_name")}</p>
                    <p className="profile-details">
                      {profileData?.cultivator?.businessName
                        ? profileData?.cultivator.businessName
                        : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <div className="customer-info">
                    <p className="customer-id">{t("experience")}</p>
                    <p className="profile-details">
                      {profileData?.cultivator
                        ? profileData?.cultivator?.yearOfExperience
                        : "N/A"}
                    </p>
                  </div>
                </Col>

                <Col sm={6} md={4} lg={4}>
                  <div className="customer-info">
                    <p className="customer-id">{t("cultivation_type")}</p>
                    <p className="profile-details">
                      {profileData?.cultivator
                        ? profileData?.cultivator?.typeOfCultivation
                        : "N/A"}
                    </p>
                  </div>
                </Col>

                <Col sm={6} md={4} lg={4}>
                  <div className="customer-info">
                    <p className="customer-id">{t("address")}</p>
                    <p className="profile-details">
                      {profileData?.address ? profileData?.address : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <div className="customer-info">
                    <p className="customer-id">{t("city")}</p>
                    <p className="profile-details">
                      {profileData?.cultivator
                        ? profileData?.cultivator?.city
                        : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col sm={6} md={4} lg={4}>
                  <div className="customer-info">
                    <p className="customer-id">{t("country")}</p>
                    <p className="profile-details">
                      {profileData?.cultivator
                        ? profileData?.cultivator?.country
                        : "N/A"}
                    </p>
                  </div>
                </Col>
                <Col md={6}>
                  <Form.Label>{t("id_proof")}</Form.Label>
                  <div className="profile-id-bottom">
                    <div className="id-front me-3">
                      <div className="">
                        <div className="upload-document position-relative">
                          <div>
                            <img
                              className="file-upload-show"
                              src={
                                profileData?.cultivator?.idProofFront? baseUrl +
                                    profileData.cultivator.idProofFront
                                  : require("../assets/images/upload.svg")
                                      .default
                              }
                              alt="img"
                            />
                          </div>
                        </div>
                        <h5 className="upload-text">
                          {" "}
                         {t("front_id")}<span></span>
                        </h5>
                      </div>
                    </div>
                    <div className="id-front">
                      <div className="">
                        <div className="upload-document position-relative">
                          <div>
                            <img
                              className="file-upload-show"
                              src={
                                profileData?.cultivator?.idProofBack
                                  ? baseUrl + profileData.cultivator.idProofBack
                                  : require("../assets/images/upload.svg")
                                      .default
                              }
                              alt="img"
                            />
                          </div>
                        </div>
                        <h5 className="upload-text">
                          {" "}
                          <span>{t("back_id")}</span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </Col>
                {profileData?.status == "Reject" && (
                  <Col lg={4}>
                    <div className="customer-info">
                      <p className="customer-id rejection-reason">
                      {t("rejection_reason")}
                      </p>
                      <p className="profile-details">
                        {profileData?.reason ? profileData?.reason : "N/A"}
                      </p>
                    </div>
                  </Col>
                )}
              </Row>
            </div>
          </div>
        </Container>
      </div>
      {/* success modal */}
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-success"
        backdrop="static"
        centered
      >
        <Modal.Header closeButton className="border-0">
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="modal-success-body">
            <Form className="login-body">
              <Form.Group className="mb-3 position-relative">
                <Form.Label>{t("cuurent_pass")}</Form.Label>
                <Form.Control
                  type={showPassOld ? "text" : "password"}
                  placeholder={t("enter_current-pass")}
                  onChange={(e) => onInputChange("oldPassword", e.target.value)}
                />
                {showPassOld ? (
                  <img
                    className="eye-icon"
                    src={require("../assets/images/eye-crossed 1.svg").default}
                    onClick={() => setshowPassOld(false)}
                    alt="img"
                  />
                ) : (
                  <img
                    className="eye-icon"
                    onClick={() => setshowPassOld(true)}
                    src={require("../assets/images/eye (1).svg").default}
                    alt="img"
                  />
                )}
                <span className="text-danger">
                  {t(changePasswordFieldsErr.oldPassword)}
                </span>
                {/* <img
                  className="eye-icon"
                  src={require("../assets/images/eye-crossed 1.svg").default}
                  alt="img"
                /> */}
              </Form.Group>
              <Form.Group className="mb-3 position-relative">
                <Form.Label> {t("new_pass")}</Form.Label>
                <Form.Control
                  type={showPass ? "text" : "password"}
                  placeholder={t("enter_new_pass")}
                  onChange={(e) => onInputChange("password", e.target.value)}
                />
                {showPass ? (
                  <img
                    className="eye-icon"
                    src={require("../assets/images/eye-crossed 1.svg").default}
                    onClick={() => setshowPass(false)}
                    alt="img"
                  />
                ) : (
                  <img
                    className="eye-icon"
                    onClick={() => setshowPass(true)}
                    src={require("../assets/images/eye (1).svg").default}
                    alt="img"
                  />
                )}
                {/* <img
                  className="eye-icon"
                  src={require("../assets/images/eye-crossed 1.svg").default}
                  alt="img"
                /> */}
                <span className="text-danger">
                  {t(changePasswordFieldsErr.password)}
                </span>
              </Form.Group>
              <Form.Group className="mb-3 position-relative">
                <Form.Label>{t("confirm_pass")}</Form.Label>
                <Form.Control
                  type={showPassConfirm ? "text" : "password"}
                  placeholder={t("enter_confirm_pass")}
                  onChange={(e) =>
                    onInputChange("confirmPassword", e.target.value)
                  }
                />
                {showPassConfirm ? (
                  <img
                    className="eye-icon"
                    src={require("../assets/images/eye-crossed 1.svg").default}
                    onClick={() => setshowPassConfirm(false)}
                    alt="img"
                  />
                ) : (
                  <img
                    className="eye-icon"
                    onClick={() => setshowPassConfirm(true)}
                    src={require("../assets/images/eye (1).svg").default}
                    alt="img"
                  />
                )}
                {/* <img
                  className="eye-icon"
                  src={require("../assets/images/eye-crossed 1.svg").default}
                  alt="img"
                /> */}
                <span className="text-danger">
                  {t(changePasswordFieldsErr.confirmPassword)}
                </span>
              </Form.Group>
              <Button className="green-btn" onClick={changePassword}>
                {t("update")}
              </Button>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default MyProfile;
