import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { useEffect, useState } from 'react';
import { apiService } from "../service/api.service";
import moment from "moment";
import { UtctolocalDate} from "../config/config"

import Pagination from "react-js-pagination";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
const Notification = () => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false)
  const [notificationData, setnotificationData] = useState()
  const [page, setpage] = useState(0)
  const [size, setsize] = useState(5)
  const [totalItems, settotalItem] = useState()
  useEffect(() => {
    getNotification(page, size)
}, [])
  function handlePageChange(pageNumber) {
    setpage(pageNumber - 1);
    getNotification(pageNumber - 1, size)
  }
  const getNotification = (page, size) => {
    apiService.notifications(page, size).then((res) => {
      settotalItem(res.data.data.total)
      // setLoader(false)
      setnotificationData(res.data.data.list)
      console.log("======Get=noti data====>", res.data.data)

      // setbankList(res.data.data)

    }).catch((err) => {
      setLoader(false)

      // setupdateShow(true)
    });
  }

  return (
    <>
      <div className="height-footer notification-section">
        <Container>
          <div className='product-header-area'>
            <h2 className='subheading-under'>{t("notification")}</h2>
          </div>
          {notificationData && notificationData.map((data, index) => {
            let orderData = JSON.parse(data.extras)
            return (<div className="notification-txt" >
              <a href={orderData && "/order-mangement/order-details/" + orderData.orderId}>
                <div className="d-flex align-items-center">
                  {orderData && orderData.status == "PICKUP_CONFIRM" ?
                    <img
                      className="me-3"
                      src={require("../assets/images/assigned.svg").default}
                      alt="img"
                    /> :
                    <>
                      {orderData && orderData.status == "ORDER_CANCELLED" ?
                        <img
                          className="me-3"
                          src={require("../assets/images/cancelled.svg").default}
                          alt="img"
                        /> :
                        <img
                          className="me-3"
                          src={require("../assets/images/placed.svg").default}
                          alt="img"
                        />}
                    </>
                  }
                  <div>
                    <h4>{data.title}
                    </h4>
                    <p>{data.description}
                    </p>
                  </div>
                </div>
              </a>
              <p className="date-txt text-right">{moment(UtctolocalDate(data?.time)).format("hh:mm a, DD MMM YYYY")}</p>
            </div>)
          })}
          {notificationData && notificationData.length == 0 &&
            <div className="nodata centre">
              <img className="" src={require("../assets/images/no-data-found.png")} alt="img"
              />
              <span>No data Found</span>
            </div>

          }
          {notificationData && notificationData.length > 0 && totalItems > size ? (
            <div className="text-right">
              <div className='pagination-tab'>
                <Pagination
                  activePage={page + 1}
                  itemsCountPerPage={size}
                  totalItemsCount={totalItems}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                //   prevPageText={"Prev"}
                //   nextPageText={"Next"}
                />
              </div>
            </div>
          ) : null}
        </Container>
      </div>
    </>
  );
};
export default Notification;
