import { Button, Container, Form, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import TabComponent from "../../../common/TabComponent";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from 'react';
import { apiService } from "../../../service/api.service";
import { baseUrl, errorHandling,formatToColombianCurrency } from '../../../config/config'
import Loader from "../../../common/Loader";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import swal from 'sweetalert';
import Pagination from "react-js-pagination";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../../../i18n';
const AddProduct = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [allProducts, setAllProducts] = useState();
    const [productIds, setProductIds] = useState([]);
    const [totalItems, settotalItems] = useState();
    const [loader, setLoader] = useState(false)
    const [catId, setcatId] = useState(null);
    const [catlist, setcatlist] = useState([]);
    const [searchName, setsearchName] = useState(null);
    const [show, setShow] = useState(false);
    const [allImages, ssetallImages] = useState();
    const handleClose = () => setShow(false);
    const handleShow = (images) => {
        console.log("=====images-----",images)
        ssetallImages(images)
        setShow(true);
    }
    const redirect = (type) => {
        navigate(type);
    }
    useEffect(() => {
        if(localStorage.getItem("profile_status")!=="Accept"){
            let message =""
            if(localStorage.getItem("profile_status")=="Pending"){
                message=t("cannot_add")
            }
            if(localStorage.getItem("profile_status")=="Reject"){
                message=t("profile_reject")
            }
            swal({
                title: t("pending_approve"),
                text: message,
                icon: "warning",
                confirmButtonText:"Ok",
            })
                .then((value) => {
                    if (value) {

                        redirect('/product-mangement/products')


                    } else {
                        console.log("NO button clicked or popup closed");
                    }
                })
            
        }else if(localStorage.getItem("stripeBankId")!="true"){
            swal({
                title: t("bank_not_added"),
                text: t("bank_err"),
                icon: "warning",
                confirmButtonText: t("add_bank"),
            })
                .then((value) => {
                    if (value) {

                        redirect('/bank-details')


                    } else {
                        console.log("NO button clicked or popup closed");
                    }
                })
            
            return
        }

        getAllProducts(page, limit, catId, searchName)
        GetCategoryList()
    }, [])
    function handlePageChange(pageNumber) {
        setPage(pageNumber - 1);
        getAllProducts(pageNumber - 1, limit, catId, searchName)
    }
    const getAllProducts = (page, limit, cat, searchName) => {
        setLoader(true)
        apiService.getAllProducts(page, limit, cat, searchName).then((res) => {
            setLoader(false)
            setAllProducts(res.data.data.list)
            settotalItems(res.data.data.total)
        }).catch((err) => {
            errorHandling(err.response.status)
            setLoader(false)
            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }
    const setproductvalues = (event,val, i) => {
        if(event.target.checked){
            setProductIds([...productIds, val])
        }else{
            setProductIds(productIds => productIds.filter(item => item !== val));
        }
        
        //  allProducts[i].selected=true
        
     console.log("====val----",productIds)

    }
    async function GetCategoryList() {
        try {
            setLoader(true)

            const response = await apiService.GetCategoryList()
            console.log("category...", response)
            if (response?.status == 200) {
                setcatlist(response?.data?.data.list)
                setLoader(false)

            }

        } catch (err) {
            errorHandling(err.response.status)

        }

    }

    // const handleProductSelect = (productId) => {
    //     if (selectedProducts.includes(productId)) {
    //       setSelectedProducts(selectedProducts.filter(id => id !== productId));
    //     } else {
    //       setSelectedProducts([...selectedProducts, productId]);
    //     }
    //   };
    const addProduct = () => {
        console.log(productIds)
        setLoader(true)
        apiService.addProduct(productIds).then((res) => {

            navigate("/product-mangement/products")
        }).catch((err) => {
            setLoader(false)
            // console.log(err)
            console.log("contact us api error", err.response);
        });


    }
    const tooltip_per_package = (
        <Tooltip id="tooltip">
          {t("per_pckage")}
        </Tooltip>
    );
    const changeCatId = (id) => {
        if(id=="All"){
            id=null
           }
        setcatId(id)
        setPage(0)
        getAllProducts(0, limit, id, searchName)

    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            // Call your function here
            setPage(0)
            getAllProducts(0, limit, catId, searchName)
        }
    };
    const handleClick = () => {
        setsearchName(null)
        setPage(0)
        getAllProducts(0, limit, catId, null)

    };
    const goBack = () => {
        navigate(-1);
      };
    const isSelected = (productId) => productIds.includes(productId);
    return (<>
        <div className="height-footer">
            {loader && <Loader />}
            <Container>
                <TabComponent />
                {/* add product section starts */}
                <div className='product-management common-under-padding add-product'>
                    <div className='product-header-area'>
                        <h2 className='subheading-under'>{t("add_product")}</h2>
                        <div className='under-heading'>
                            <div className='search-box position-relative'>
                                <img
                                    className="search-img"
                                    src={require("../../../assets/images/search.svg").default}
                                    alt="img"
                                    onClick={() => getAllProducts(0, limit, catId, searchName)}
                                />
                                <Form.Control type="input" placeholder={t("search_name")}value={searchName == null ? "" : searchName} onChange={(e) => setsearchName(e.target.value)} onKeyDown={handleKeyPress} />
                                {searchName != null && <i class="fa fa-times" aria-hidden="true" onClick={() => handleClick()}></i>}
                            </div>
                            <div className='under-heading me-3 mt-0 add-product-select'>
                                <Form.Select aria-label="Default select example" onChange={(e) => changeCatId(e.target.value)}>
                                    <option value="All">{t("select_cat")}</option>
                                    {catlist.map((cat, i) => (
                                        <option key={i} value={cat.id} >
                                            {cat.name}
                                        </option>
                                    ))}
                                </Form.Select>
                            </div>
                            {/* <Button className='green-btn' onClick={() => { redirect('/product-mangement/add-product') }}> + Add New Product</Button> */}
                        </div>
                    </div>
                    <div className='table-data add-product-section'>
                        <table class="table product-view-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className="add-new-product">{t("product_name")}</th>
                                    <th>{t("cat")}</th>
                                    <th>{t("description")}</th>
                                    <th>{t("packaging")}</th>
                                    <th className='price-text-add-product'>{t("price")} <OverlayTrigger placement="top" overlay={tooltip_per_package}><i className="fa-solid fa-circle-exclamation"></i></OverlayTrigger></th>
                                    {/* <th>Action</th> */}
                                </tr>
                            </thead>
                            <tbody>
                                {allProducts && allProducts.map((data, i) => {
                                    let selected = productIds.includes(data.id)



                                    return (<tr>
                                        <td>
                                            <Form.Check aria-label="option" name={data.id} onClick={(e) => setproductvalues(e,data.id, i)}
                                                checked={selected}
                                            />
                                        </td>
                                        <td className="add-new-product">
                                        <div className='product-outer-main'>
                                            #{data.id}
                                            <span className='product-img cursor-pointer position-relative multiple-product-img' onClick={()=>handleShow(data?.images)}>
                                                <i className="fa-solid fa-eye"></i>
                                                {data?.images?
                                                <img
                                                    className=""
                                                    src={baseUrl + data?.images[0]?.path}
                                                    alt="img"
                                                />
                                                
                                                :   <img
                                                className=""
                                                src={require("../../../assets/images/logo.png")}
                                                alt="img"
                                            />}
                                            </span>
                                           
                                            {data.name}
                                            </div>
                                        </td>
                                        <td>{data.category}</td>
                                        <td className="white-space-inherit"><p className='main-add-description '>{data.description}</p> </td>
                                        <td>{data.packaging+" "+data.unit} </td>
                                        <td>${formatToColombianCurrency(data.price)} </td>
                                        {/* <td>
                                        <div className='action-data-box'>
                                            <Link to="/product-mangement/product-inventory">
                                                <img
                                                    className=""
                                                    src={require("../../../assets/images/product.svg").default}
                                                    alt="img"
                                                />
                                            </Link>
                                        </div>
                                    </td> */}
                                    </tr>)
                                }
                                )}



                            </tbody>
                        </table>
                        {allProducts && allProducts.length == 0 &&
                            <div className='nodata centre'>
                                <img className="" src={require("../../../assets/images/no-data-found.png")} alt="img"
                                />
                                <span>{t("no_data_found")}</span>
                            </div>

                        }
                    </div>

                    <div className="d-flex justify-content-end">
                       
                            {allProducts && allProducts.length > 0 && totalItems > 10 ? (
                                 <div className="pagination-tab">
                                <Pagination
                                    activePage={page + 1}
                                    itemsCountPerPage={10}
                                    totalItemsCount={totalItems}
                                    pageRangeDisplayed={5}
                                    onChange={(e) => handlePageChange(e)}
                                //   prevPageText={"Prev"}
                                //   nextPageText={"Next"}
                                />
                                </div>
                            ) : null}
                        
                    </div>
                    {allProducts && allProducts.length >0 &&
                    <div className="action-btn-add">
                        <Button onClick={goBack} className="green-btn radius-btn cancel-btn-outline reject-button">{t("cancel")}</Button>
                        <Button className="green-btn radius-btn" onClick={addProduct}>{t("add")}</Button>
                    </div>}
                </div>
                {/* add product section ends */}
            </Container>
        </div>
        {/* multiple images modal */}
        <Modal className="preview-box-modal" show={show} onHide={handleClose} centered> 
            <Modal.Header closeButton>
                <Modal.Title>{t("preview")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="preview-images-section">
                    <Swiper
                        navigation={true} modules={[Navigation]} 
                        slidesPerView={1}
                    >{allImages ?
                        <>
                        {allImages && allImages.map((data)=>{
                           return(<SwiperSlide>
                            <img
                                className="product-images-preview"
                                src={baseUrl+data.path}
                                alt="img"
                            />
                            </SwiperSlide>)
                        })}
                      
                      </>:

                         <img
                                className="product-images-preview"
                                src={require("../../../assets/images/logo.png")}
                                alt="img"
                            />}
                    </Swiper>
                </div>
            </Modal.Body>
        </Modal>

    </>)
}

export default AddProduct;