import { Button, Col, Container, Form, Row } from "react-bootstrap";
import TabComponent from "../../../common/TabComponent";
import Select from "react-select";
import { useEffect, useState } from 'react';
import { apiService} from "../../../service/api.service";
import {baseUrl,errorHandling,formatToColombianCurrency} from "../../../config/config"
import { Link, useNavigate,useParams} from "react-router-dom";
import Loader from "../../../common/Loader";
import moment from "moment";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../../../i18n';
const ProductDetail = () => {
    const [loader, setLoader] = useState(false)
    const { t } = useTranslation();
    const [productData,setproductData]=useState()
    const [qty,setqty]=useState()
    const [images,setimages]=useState()
    const [stockHis, setStockHis] = useState([])
    let props = useParams()
    const options = [
        { value: "PIECE", label: "PIECE" },
        { value: "KILOGRAM", label: "KILOGRAM" },
        { value: "GRAM", label: "GRAM" },
      
    ];

     useEffect(() => {
        if(localStorage.getItem("web_token")){
            getProductDetail(props.productCultivatorId)
            getProductImages(props.productId)
            getStocksHistory(props.productCultivatorId)
        }else{
            errorHandling(401)
        }
    
         },[])
         const getStocksHistory = (id) => { 
            setLoader(true)
          apiService.getStocksHistory(id).then((res) => {
                setStockHis(res.data.data.list)
                setLoader(false)
            }).catch((err) => {
                setLoader(false)
                // console.log(err)
                console.log("contact us api error", err.response);
            });
        }
         const getProductDetail = (id)=>{
            // setLoader(true)
            apiService.getProductDetail(id).then((res) => {
                setproductData(res.data.data.resource.product)
                setqty(res.data.data.resource.quantity)
                console.log("pro====",res.data.data.resource)
                // setLoader(false)
             }).catch((err) => {
                //  setLoader(false)
                 errorHandling(err.response.status)
              
                // console.log(err)
               console.log("contact us api error", err.response);
              });
        }
        const getProductImages = (id)=>{
            // setLoader(true)
            apiService.getProductImages(id).then((res) => {
             
               setimages(res.data.data.list)
                // setLoader(false)
             }).catch((err) => {
                //  setLoader(false)
                // console.log(err)
               console.log("contact us api error", err.response);
              });
        }
        
      


    return (<>
                {/* product inventory system start */}
                <Container>
                    <div className="mb-3">
                    <TabComponent />
                    </div>
                    <div className="stock-shadow">
                        <Form className="login-body mt-3">
                            <Form.Group className="mb-4" >
                                <Form.Label>{t("product_img")}</Form.Label>
                                <div className="outer-product-img">
                                    {images && images.map((img,i)=>{

                                    
                                   return(<div className="product-img-box" key={i}>
                                        <img className="product-img-under"
                                            src={baseUrl+img.path}
                                            alt="img"/>
                                    </div>)
                                    })}
                                  
                                 
                                </div>
                            </Form.Group>
                            <Row>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>{t("product_name")}</Form.Label>
                                        <p className="product-name">{productData?.name}</p>
                                    </Form.Group>
                                </Col>
                                <Col md={6}>
                                    <Form.Group className="mb-4">
                                        <Form.Label>{t("cat")}</Form.Label>
                                        <p className="product-name">{productData?.category?.name}</p>
                                    </Form.Group>
                                </Col>
                                <Col md={12}>
                                    <Form.Group className="mb-4" controlId="exampleForm.ControlTextarea1">
                                        <Form.Label>{t("description")} </Form.Label>
                                        <p className="product-name">{productData?.description} </p>
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Form>
                    </div>
                    <div className='product-header-area mt-4'>
                        <h2 className='subheading-under'>{t("stock")}</h2>
                       
                            {/* <Link to={"/product-mangement/product-inventory/" + props.productId + "/" + props.productCultivatorId}>
                                <Button className="green-btn radius-btn small-btn" >Add Stock</Button></Link> */}
                    </div>
                    <div className='table-data'>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>{t("s_no")}</th>
                                    <th>{t("packaging")}</th>
                                    <th>{t("unit")}</th>
                                    <th>{t("available_stock")}	</th>
                                    <th>{t("price")}</th>
                                    {/* <th>View Stock</th> */}
                                </tr>
                            </thead>
                            <tbody>
                               
                                    <tr key={1}>
                                        <td>{ 1}</td>
                                        <td>{productData?.packaging}</td>
                                        <td>{productData?.unit.unit}</td>
                                        <td>{qty?qty:0}</td>
                                        <td>${formatToColombianCurrency(productData?.price)}</td>
                                    
                                        {/* <td style={{ cursor: "pointer" }} onClick={() => getStocksHistory(data.id, index)}>
                                        <OverlayTrigger placement="top" overlay={tooltip}>
                                            <i className={activeIndex == index ? 'fa fa-eye active' : 'fa fa-eye'}></i>
                                            </OverlayTrigger></td> */}
                                    </tr>
     


                            </tbody>
                        </table>
                        </div>
                        {stockHis && stockHis.length > 0 &&
                    <>
                    <div className='product-header-area mt-4'>
                        <h2 className='subheading-under'>{t("view_his")}</h2>
                        {/* <Link to={"/product-mangement/product-inventory/"+props.productId+"/"+props.productCultivatorId}><Button className="green-btn radius-btn small-btn" >Add Stock</Button></Link> */}
                    </div>
                    <div className='table-data view-stock-history inner-table-view'>
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>{t("s_no")}</th>
                                    <th>{t("date")}</th>
                                    <th>{t("in")}</th>
                                    <th>{t("out")}</th>
                                    <th>{t("net_stock")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {stockHis.map((data, index) => {
                                 let newDate = moment.utc(data?.createdDate).local()
                             
                               return (
                                   
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{moment(newDate).format("DD/MM/YYYY hh:mm A")}
                                        </td>
                                        <td>{data.plusQ}</td>
                                        <td>{data.minusQ}</td>
                                        <td>{data.leftQ}</td>
                                    </tr>
                                )
                            }
                                )
                            }



                            </tbody>
                        </table>
                        {stockHis && stockHis.length == 0 &&
                                <div className="nodata centre">
                                    <img className="" src={require("../../../assets/images/no-data-found.png")} alt="img"
                                    />
                                    <span>{t("no_data_found")}</span>
                                </div>

                            }
                    </div>
                    </>}
                    </Container>
             
         
    </>)
}

export default ProductDetail;