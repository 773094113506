import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import swal from 'sweetalert';
import {timeRemaining,errorHandling} from "../../config/config"
import { useEffect, useState,useRef } from "react";
import { apiService } from "../../service/api.service";
import { useTranslation, initReactI18next } from "react-i18next";
import i18n from "i18next";
import "../../i18n";
import ProgressTimer from "./progressTimer";
const TimerComponent = (props) => {
  console.log("======props.time=====",props.time,props.data.orderId)
    let data = props.data
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false);
  const [email, setemail] = useState("");
  const [timeLeft, settimeLeft] = useState(props.time?props.time:0);
  const timeLeftData = useRef(0)
  let history = useNavigate();
  const tooltip = (
    <Tooltip id="tooltip">
        {t("view_tooltip")}
    </Tooltip>
);
// props.Getorders(props.page,props.limit,props.status,props.search)
const acceptRejectOrder = (status) => {
  let params = {
      orderId: props.data.orderId,
      status: status
  }
  setLoader(true)
  apiService.acceptRejectOrder(params).then((res) => {
      if (status == "Accept") {
          swal(t("success"), t("order_accpet"), "success")
      } else {
          swal(t("success"), t("order_reject"), "success")
      }
      if(props.type=="List"){
        props.getOrders(props.page,props.limit,props.status,props.search)
      }else{
        props.getCountDashboard()
        props.getOrdersDashboard()
      }
      
     
      console.log("order====", res.data)
      setLoader(false)
  }).catch((err) => {
      setLoader(false)
      swal("Error", err.response.data.message, "error")
      errorHandling(err.response.status)
     
      // console.log(err)
      console.log("contact us api error", err.response);
  });
}
useEffect(() => {
    let time = timeRemaining(props.data.orderDate,props.responseTime)
    console.log("====time=====",time)
    timeLeftData.current = time
    settimeLeft(time)

}, []);
  return (
    <>

    <div>
      <div className="action-data-box updated-action-data-box">
        <OverlayTrigger placement="top" overlay={tooltip}>
          <Link to={"/order-mangement/order-details/" + data.orderId}>
            <img
              className=""
              src={require("../../assets/images/eye (2).svg").default}
              alt="img"
            />
          </Link>
        </OverlayTrigger>
        {/* {data.status} */}
        {(data.status == "ORDER_INITIATED" || data.status == "AWAITING_PAYMENT") && props.time >0 ?
        <div className="action-btn">
          <Button className="accept-order" onClick={()=>acceptRejectOrder("Accept")}>{t("accept")}</Button>
          <Button className="accept-order reject-order" onClick={()=>acceptRejectOrder("Reject")}>{t("reject")}</Button>
        </div>:null}
      </div>
      {(data.status == "ORDER_INITIATED" || data.status == "AWAITING_PAYMENT") && props.time >0 ?
      <div className="outer-progress-bar">
        <ProgressTimer createdAt={data.orderDate}  responseTime={props.responseTime}/></div>:null}
      </div>
    </>
  );
};
export default TimerComponent;
