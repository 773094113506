import { useState, useEffect } from 'react';
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip, Modal } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import TabComponent from "../../../common/TabComponent";
import { apiService } from "../../../service/api.service";
import { baseUrl, errorHandling,formatToColombianCurrency } from "../../../config/config"
import Loader from "../../../common/Loader";
import Pagination from "react-js-pagination";
import swal from 'sweetalert';
import moment from "moment";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../../../i18n';
const ProjectManagementView = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [catlist, setcatlist] = useState([]);
    const [allProducts, setAllProducts] = useState();
    const [totalItems, settotalItems] = useState();
    const [searchName, setsearchName] = useState(null);
    const [catId, setcatId] = useState(null);
    const [loader, setLoader] = useState(false)
    const [showStock, setShowStock] = useState(false);
    const [availableStock, setavailableStock] = useState();
    const [cultivatorId, setcultivatorId] = useState();
    const [availableStockErr, setavailableStockErr] = useState("");
    const handleCloseStock = () => setShowStock(false);
    const handleShowStock = () => setShowStock(true);
    const [profile_status, setprofile_status] = useState(localStorage.getItem("profile_status"))

    const redirect = (type) => {
        navigate(type);
    }
    useEffect(() => {
       
        if(localStorage.getItem("web_token")){
            getProducts(page, limit, catId, searchName)
            GetCategoryList()
        }else{
            errorHandling(401)
        }
      
    }, [])
    function handlePageChange(pageNumber) {
        setPage(pageNumber - 1);
        getProducts(pageNumber - 1, limit, catId, searchName)
    }
    async function GetCategoryList() {
        try {
            setLoader(true)

            const response = await apiService.GetCategoryList()
            console.log("category...", response)
            if (response?.status == 200) {
                setcatlist(response?.data?.data.list)
                setLoader(false)

            }

        } catch (err) {
            errorHandling(err.response.status)

        }

    }
    const getProducts = (page, limit, cat, name) => {
        setLoader(true)
        apiService.getProducts(page, limit, cat, name).then((res) => {
            setLoader(false)
            console.log("res======", res)
            settotalItems(res.data.data.total)
            setAllProducts(res.data.data.list)

        }).catch((err) => {
            errorHandling(err.response.status)
            setLoader(false)
            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }
    const changeCatId = (id) => {
        if (id == "All") {
            id = null
        }
        setcatId(id)
        setPage(0)
        getProducts(0, limit, id, searchName)

    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter' && searchName && searchName.trim() != "") {
            // Call your function here
            setPage(0)
            getProducts(0, limit, catId, searchName)
        }
    };
    const handlesearch = () => {
        if (searchName && searchName.trim() != "") {
            // Call your function here

            setPage(0)
            getProducts(0, limit, catId, searchName)
        }
    };
    const stockAvailableView = (id, qty) => {
        setavailableStock(qty)
        setcultivatorId(id)
        handleShowStock()


    }
    const addStock = () => {
        if (!availableStock) {
            setavailableStockErr(t('stock_err'))
            return false
        }
        apiService.addStock(cultivatorId, availableStock).then((res) => {
            handleCloseStock()
            swal(t("success"), t("stock_added"), "success")
            getProducts(page, limit, catId, searchName)
            setLoader(false)
            console.log("======res====", res)
        }).catch((err) => {
            setLoader(false)
            // console.log(err)
            console.log("contact us api error", err);
        });
    }
    const tooltip = (
        <Tooltip id="tooltip">
            {t("product_inventry")}
        </Tooltip>
    );
    const tooltip_view = (
        <Tooltip id="tooltip">
            {t("view_tooltip")}
        </Tooltip>
    );
    const tooltip_per_package = (
        <Tooltip id="tooltip">
            
           {t("per_pckage")}
        </Tooltip>
    );
    const handleClick = () => {
        setsearchName(null)
        setPage(0)
        getProducts(0, limit, catId, null)

    };
    const handleKeyDownnumber = (event) => {
        console.log("=====event====", event.which);
        if (event.which === 32) {
            event.preventDefault(); // Prevent space key action
        }
        if (event.which === 189) {
            event.preventDefault(); // Prevent space key action
        }
        if (event.key === "Enter" || event.which === 13) {
            event.preventDefault(); // Prevent the default behavior (page refresh)
            // Handle your logic here
        }
    };
    return (<>
        <div className="height-footer">
            {loader && <Loader />}
            <Container>
                <TabComponent />
                <div className="tab-pane" id="product-management">
                    <div className='product-management common-under-padding'>
                        <div className='product-header-area status-bar'>
                            <h2 className='subheading-under'>{t("product_mangement")}</h2>
                            <div className='under-heading'>
                                <div className='search-box position-relative'>
                                    <img
                                        className="search-img"
                                        src={require("../../../assets/images/search.svg").default}
                                        alt="img"
                                        onClick={() => handlesearch()}
                                    />
                                    <Form.Control type="input" placeholder={t("search_product")} value={searchName == null ? "" : searchName} onChange={(e) => setsearchName(e.target.value)} onKeyDown={handleKeyPress} />
                                    {searchName != null && <i class="fa fa-times" aria-hidden="true" onClick={handleClick}></i>}
                                </div>
                                <div className='select-box-mobile me-3'>
                                    <Form.Select aria-label="Default select example" onChange={(e) => changeCatId(e.target.value)}>
                                        <option value="All">{t("select_cat")}</option>
                                        {catlist.map((cat, i) => (
                                            <option key={i} value={cat.id} >
                                                {cat.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </div>
                                {profile_status == "Accept" ?
                                    <>
                                    {console.log("=====bank>>>",localStorage.getItem("stripeBankId")!="true")}
                                        {(localStorage.getItem("stripeBankId")!= "true" ) ?
                                            <Button className='green-btn not_added' onClick={() => {

                                                swal({
                                                    title:t('bank_not_added'),
                                                    text: t('bank_err'),
                                                    icon:"warning",
                                                    buttons: [t("cancel"), t("add_bank")],
                                                })
                                                    .then((value) => {
                                                        if (value) {

                                                            redirect('/bank-details')


                                                        } else {
                                                            console.log("NO button clicked or popup closed");
                                                        }
                                                    })

                                            }}> + {t('add_product')} </Button> :
                                            <Button className='green-btn' onClick={() => { redirect('/product-mangement/add-product') }}> + {t('add_product')}</Button>

                                        }
                                    </> :
                                    <Button className='green-btn disabled' onClick={() => { redirect('/product-mangement/add-product') }}> + {t('add_product')}</Button>
                                }
                            </div>
                        </div>
                        <div className='table-data'>
                            <table class="table product-view-table">
                                <thead>
                                    <tr>
                                        <th>#{t("product_id")}</th>
                                        <th>{t("product_name")}</th>
                                        <th>{t("cat")}</th>
                                        <th>{t("packaging")}</th>
                                        <th>{t("unit")}</th>
                                        {/* <th>Available Stock</th> */}
                                        <th className='price-text'>{t("price")} <OverlayTrigger placement="top" overlay={tooltip_per_package}><i className="fa-solid fa-circle-exclamation"></i></OverlayTrigger></th>
                                        <th>{t("description")}</th>
                                        <th>{t("created_at")}</th>
                                        <th>{t("action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allProducts && allProducts.map((data, i) => {
                                        return (<tr>
                                            <td className="">{data.id}</td>
                                            <td>
                                                <div className='product-outer-main'>
                                                    <span className='product-img'>
                                                        <img
                                                            className=""
                                                            src={baseUrl + data.path}
                                                            alt="img"
                                                        />
                                                    </span>
                                                    {data.name}
                                                </div>
                                            </td>
                                            <td>{data.category}</td>
                                            <td>{data.packaging}</td>
                                            <td>{data.unit}</td>
                                            {/* <td>{data.quantity}</td> */}
                                            <td>${formatToColombianCurrency(data.price)}</td>
                                            <td><p className='description-txt'>{data.description}</p> </td>
                                            <td><p>{moment(data?.createdDate).format("DD MMM, YYYY")}</p> </td>
                                            <td>


                                                <div className='action-data-box position-relative'>

                                                    <OverlayTrigger placement="top" overlay={tooltip_view}>
                                                        <Link to={"/product-mangement/view-inventory/" + data.id + "/" + data.productCultivatorId}><i className='fa fa-eye'></i></Link>
                                                    </OverlayTrigger>
                                                    <span className='quantity-txt'>{data.quantity}</span>

                                                    <OverlayTrigger placement="top" overlay={tooltip} >
                                                        {/* <Link
                                                         to={"/product-mangement/product-inventory/" + data.productId + "/" + data.id}
                                                        > */}
                                                        <img className="product-card-img" src={require("../../../assets/images/product.svg").default}
                                                            alt="img"
                                                            onClick={() => stockAvailableView(data.productCultivatorId, data.quantity)} />
                                                        {/* </Link> */}
                                                    </OverlayTrigger>

                                                </div>
                                            </td>
                                        </tr>
                                        )
                                    }
                                    )}



                                </tbody>
                            </table>
                            {allProducts && allProducts.length == 0 &&
                                <div className="nodata centre">
                                    <img className="" src={require("../../../assets/images/no-data-found.png")} alt="img"
                                    />
                                    <span>{t("no_data_found")}</span>
                                </div>

                            }

                        </div>
                        {allProducts && allProducts.length > 0 && totalItems > limit ? (
                            <div className="text-right">
                                <div className='pagination-tab'>
                                    <Pagination
                                        activePage={page + 1}
                                        itemsCountPerPage={limit}
                                        totalItemsCount={totalItems}
                                        pageRangeDisplayed={5}
                                        onChange={(e) => handlePageChange(e)}
                                    //   prevPageText={"Prev"}
                                    //   nextPageText={"Next"}
                                    />
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Container>
        </div>
        {/* success modal */}
        <Modal
            show={showStock}
            onHide={handleCloseStock}
            className="modal-success quantity-box"
            backdrop="static"
            centered
        >
            <Modal.Header closeButton className="border-0">
                <Modal.Title>{t("add_stock")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-success-body">
                    <Form className="login-body quantity-box-inner">
                        <Form.Group className="position-relative">
                            <div class="cart-input">
                                <button type='button' className={availableStock == 0 ? "minus-btn disabled" : "minus-btn"} onClick={() => {
                                    if (availableStock > 0) { setavailableStock(parseInt(availableStock) - 1) }
                                }}>-</button>
                                {/* <input type="number" class="quantity-input" value="1" min="1"/> */}
                                <Form.Control class="quantity-input" onKeyDown={handleKeyDownnumber} maxLength={8}
                                    onWheel={() => document.activeElement.blur()}
                                    type="number"
                                    placeholder={t("enter")}
                                    value={availableStock}
                                    onChange={(e) => {
                                        const inputValue = e.target.value;
                                        const sanitizedValue = inputValue.replace(/-/g, '');
                                        //   if (e.target.value.length <= 3) {
                                        setavailableStock(sanitizedValue)
                                        //   } else {
                                        //     return false;
                                        //   }
                                    }}
                                />
                                <button type='button' className="plus-btn" onClick={() => setavailableStock(parseInt(availableStock) + 1)}>+</button>
                            </div>

                            <span className="text-danger">
                                {availableStockErr}
                            </span>

                        </Form.Group>
                        <div className='text-center'>
                            <Button type="button" className="green-btn" onClick={addStock}>
                            {t("save")}
                            </Button>
                        </div>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    </>)
}

export default ProjectManagementView;