import { Button, Col, Container, Form, Row } from "react-bootstrap";
import TabComponent from "../../../common/TabComponent";
import Select from "react-select";
import { useEffect, useState } from 'react';
import { apiService } from "../../../service/api.service";
import { baseUrl, errorHandling } from "../../../config/config"
import { Link, redirect, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../common/Loader";
import ProductDetail from "./ProductDetail"
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../../../i18n';
const ProductInventory = () => {
    const navigate = useNavigate()
    const [loader, setLoader] = useState(false)
    let props = useParams()
    const options = [
        { value: "PIECE", label: "PIECE" },
        { value: "KILOGRAM", label: "KILOGRAM" },
        { value: "GRAM", label: "GRAM" },

    ];
    const [errors, setErrors] = useState([]);
    const [unitData, setunitData] = useState([]);
    useEffect(() => {
        getUnitList()
        getstock()
    }, [])
    const [stockValues, setStockValues] = useState([{

        "quantity": "",
        "unitId": "",
        "availableStock": "",
        "originalPrice": "",
        "discountedPrice": "",
        "newAdded":true
    }]);



    const addMoreStock = () => {
        var temp = [...stockValues];
        temp.push({ quantity: "", unitId: "", availableStock: "", originalPrice: "", discountedPrice: "",newAdded:true });
        console.log(temp);
        setStockValues(temp);
    };
    function handleChangeStock(i, event, _key1) {
        const values = [...stockValues];

        if (_key1 == "quantity") {
            values[i].quantity = event;
        }
        if (_key1 == "unitId") {
            values[i].unitId = event;
        }
        if (_key1 == "availableStock") {
            values[i].availableStock = event;
        }
        if (_key1 == "originalPrice") {
            values[i].originalPrice = event;
        }
        if (_key1 == "discountedPrice") {
            values[i].discountedPrice = event;
        }
        if (_key1 == "unitId") {
            values[i].unitId = event;
        }
        setStockValues(values)
    }
    const deleteStock = (i) => {
        var temp = [...stockValues];
        temp.splice(i, 1);
        setStockValues(temp);
    };
    const addStock = () => {

        // "quantity": "",
        // "unitId": "",
        // "availableStock": "",
        // "originalPrice": "",
        // "discountedPrice": ""
        let valid = true
        const newErrors = stockValues.map(field => {
            const errors = {};

            if (!field.quantity) {
                errors.quantity = 'Quantity is required';
                valid = false
            }
            if (!field.unitId) {
                errors.unitId = 'Packaging is required';
                valid = false
            }
            if (!field.originalPrice) {
                errors.originalPrice = 'Price is required';
                valid = false
            }
            if (!field.discountedPrice) {
                errors.discountedPrice = 'Discount Price is required';
                valid = false
            }
            return errors;
        });
        console.log("errppppp====", errors)
        setErrors(newErrors);

        // return
        if (valid) {
            setLoader(true)
            let params = {
                productCultivatorId: props.productCultivatorId,
                stocks: stockValues
            }
            console.log(params)
            apiService.addStock(params).then((res) => {
                navigate("/product-mangement/view-inventory/" + props.productId + "/" + props.productCultivatorId)
                setLoader(false)
            }).catch((err) => {
                setLoader(false)
                // console.log(err)
                console.log("contact us api error", err.response);
            });
        }
    }
    const getUnitList = () => {
        apiService.GetUnitList().then((res) => {
            console.log("===unit====", res.data.data.list)
            let unitsData = [];
            res.data.data.list.map((data) => {
                unitsData.push({ value: data.id, label: data.unit })

            })
            setunitData(unitsData)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }

    const getstock = () => {
        apiService.getStocks(props.productCultivatorId).then((res) => {

            console.log("===stock====", res.data.data.list)
            let stockdata = [];

            res.data.data.list.map((data) => {
                stockdata.push({
                    "id": data.id,
                    "quantity": data.quantity,
                    "unitId": data.unit.id,
                    "units": data.unit,
                    "availableStock": 0,
                    "stock": data.availableStock,
                    "originalPrice": data.originalPrice,
                    "discountedPrice": data.discountedPrice,
                    "newAdded": false
                })

            })
            if (stockdata.length > 0) {
                setStockValues(stockdata)
            }

            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }
    const removestock = () => {
        apiService.getStocks(props.productCultivatorId).then((res) => {

            console.log("===stock====", res.data.data.list)
            let stockdata = [];
            res.data.data.list.map((data) => {
                stockdata.push({
                    "id": data.id,
                    "quantity": data.quantity,
                    "unitId": data.unit.id,
                    "availableStock": data.availableStock,
                    "originalPrice": data.originalPrice,
                    "discountedPrice": data.discountedPrice
                })

            })
            setStockValues(stockdata)
            setLoader(false)
        }).catch((err) => {
            setLoader(false)
            // console.log(err)
            console.log("contact us api error", err.response);
        });
    }
    const handleIncrement = () => {
        // setQuantity(quantity + 1);
    };

    const handleDecrement = () => {
        // if (quantity > 1) {
        //   setQuantity(quantity - 1);
        // }
    };
    return (<>
        <div className="height-footer">
            {loader && <Loader />}
            <Container>
                <TabComponent />
                {/* product inventory system start */}
                <div className="product-invntory common-under-padding">
                    <div className='product-header-area'>
                        <h2 className='subheading-under'>Product Inventory</h2>
                    </div>
                    <ProductDetail productId={props.productId} setLoader={setLoader} />
                    {/* <div className='product-header-area mt-4'>
                        <h2 className='subheading-under'>Add Product Stock</h2>
                        <Button className="green-btn radius-btn small-btn" onClick={addMoreStock}>+ Add more</Button>
                    </div> */}
                    <div className="stock-shadow">
                        <Form className="login-body mt-3 add-inventory">


                            {stockValues.map((data, index) => {
                                let selectedunit;
                                unitData.forEach(obj => {
                                    // Access the desired property of each object (e.g., name)
                                    if (obj.value === data.unitId) {
                                        selectedunit = obj

                                        console.log('Found John in the array!');
                                    }
                                });
                                return (
                                    <>
                                        {index == 0 ?
                                            <table className="">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <Form.Label>Packaging</Form.Label>
                                                        </th>
                                                        <th>
                                                            <Form.Label>Actual Price</Form.Label>
                                                        </th>
                                                        <th>
                                                            <Form.Label>Discount Price</Form.Label>
                                                        </th>
                                                        <th>
                                                            <Form.Label>Available Stock</Form.Label>
                                                        </th>
                                                        <th>
                                                            <Form.Label>Add Stock</Form.Label>
                                                        </th>
                                                        <th>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Form.Group className="mb-4">

                                                                <div className="d-flex">
                                                                    <Form.Control type="text" placeholder="0" value={data.quantity} onChange={(e) => handleChangeStock(index, e.target.value, "quantity")} className={errors[index]?.quantity && "border-error"} />

                                                                    <Select className={errors[index]?.unitId ? "border-error w-100 ms-3" : "w-100 ms-3"} styles={{
                                                                        control: (baseStyles, state) => ({
                                                                            ...baseStyles,
                                                                            background: "transparent",
                                                                            border: "1px solid black",
                                                                            boxShadow: "none",

                                                                            "&:focus": {
                                                                                borderColor: "black",
                                                                            },
                                                                            "&:hover": {
                                                                                borderColor: "black",
                                                                            },
                                                                        }),
                                                                    }}
                                                                        value={selectedunit}
                                                                        onChange={(e) => handleChangeStock(index, e.value, "unitId")}
                                                                        options={unitData}
                                                                        />
                                                                </div>
                                                            </Form.Group>   
                                                        </td>
                                                        <td>
                                                            <Form.Group className="mb-4">
                                                                <Form.Control type="text" placeholder="0" value={data.originalPrice} onChange={(e) => handleChangeStock(index, e.target.value, "originalPrice")} className={errors[index]?.originalPrice && "border-error"} />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group className="mb-4">
                                                                <Form.Control type="text" placeholder="0" value={data.discountedPrice} onChange={(e) => handleChangeStock(index, e.target.value, "discountedPrice")} className={errors[index]?.discountedPrice && "border-error"} />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group className="mb-4 add-stock-section">

                                                                <Form.Control type="text" placeholder="0 " disabled value={data.stock} onChange={(e) => handleChangeStock(index, e.target.value, "availableStock")} />

                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            <Form.Group className="mb-4 add-stock-section">
                                                                <Form.Control type="text" placeholder="0  " onChange={(e) => handleChangeStock(index, e.target.value, "availableStock")} />
                                                            </Form.Group>
                                                        </td>
                                                        <td>
                                                            {stockValues.length > 1 && index > 0 && !data.id && data.newAdded &&
                                                                <i className="fa fa-trash stock-trash" onClick={()=>deleteStock(index)}></i>
                                                            }
                                                            {/* delete icon here */}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table> :
                                            <tbody>

                                                <tr>
                                                    <td>
                                                        <Form.Group className="mb-4">
                                                            {/* <Form.Label>Packaging</Form.Label> */}
                                                            <div className="d-flex">
                                                                <Form.Control type="text" placeholder="0" value={data.quantity} onChange={(e) => handleChangeStock(index, e.target.value, "quantity")} className={errors[index]?.quantity && "border-error"} />

                                                                <Select className={errors[index]?.unitId ? "border-error w-100 ms-3" : "w-100 ms-3"} styles={{
                                                                    control: (baseStyles, state) => ({
                                                                        ...baseStyles,
                                                                        background: "transparent",
                                                                        border: "1px solid black",
                                                                        boxShadow: "none",

                                                                        "&:focus": {
                                                                            borderColor: "black",
                                                                        },
                                                                        "&:hover": {
                                                                            borderColor: "black",
                                                                        },
                                                                    }),
                                                                }}
                                                                    value={selectedunit}
                                                                    onChange={(e) => handleChangeStock(index, e.value, "unitId")}
                                                                    options={unitData}
                                                                />
                                                            </div>
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group className="mb-4">
                                                            {/* <Form.Label>Actual Price</Form.Label> */}
                                                            <Form.Control type="text" placeholder="0" value={data.originalPrice} onChange={(e) => handleChangeStock(index, e.target.value, "originalPrice")} className={errors[index]?.originalPrice && "border-error"} />
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group className="mb-4">
                                                            {/* <Form.Label>Discount Price</Form.Label> */}
                                                            <Form.Control type="text" placeholder="0" value={data.discountedPrice} onChange={(e) => handleChangeStock(index, e.target.value, "discountedPrice")} className={errors[index]?.discountedPrice && "border-error"} />
                                                        </Form.Group>
                                                    </td>

                                                    <td>
                                                        <Form.Group className="mb-4 add-stock-section">
                                                            {/* <Form.Label>Available Stock</Form.Label> */}

                                                            <Form.Control type="text" placeholder="0 " disabled value={data.stock} onChange={(e) => handleChangeStock(index, e.target.value, "availableStock")} />

                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        <Form.Group className="mb-4 add-stock-section">
                                                            {/* <Form.Label>Add Stock</Form.Label> */}
                                                            <Form.Control type="text" placeholder="0  " onChange={(e) => handleChangeStock(index, e.target.value, "availableStock")} />
                                                        </Form.Group>
                                                    </td>
                                                    <td>
                                                        {stockValues.length > 1 && index > 0 && !data.id && data.newAdded &&
                                                            <i className="fa fa-trash stock-trash" onClick={()=>deleteStock(index)}></i>
                                                        }
                                                        {/* delete icon here */}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        }
                                    </>

                                )
                            })}

                        </Form>
                    </div>
                </div>
                <div className="action-btn-add mt-4">
                    <Button className="green-btn radius-btn cancel-btn-outline reject-button" onClick={() => navigate(-1)}>Cancel</Button>
                    <Button className="green-btn radius-btn" onClick={addStock}>Add stock</Button>
                </div>
                {/* product inventory system ends */}
            </Container>
        </div>
    </>)
}

export default ProductInventory;