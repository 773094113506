import { Button, Col, Container, Form, Row } from "react-bootstrap";
const AboutUsSpanish = () => {
    return (
        <>
            <section className="height-footer about-us-spanish">
                {/* <div className="about-us-section">
                    <Container>
                        <h2>Sobre nosotras</h2>
                    </Container>
                </div> */}
                {/* about us content */}
                <Container>
                    <Row className="mt-4">
                        <Col md={12}>
                            <h5 className="text-justify">¡Bienvenido a Semilla, donde llevamos la finca a tu mesa! Nuestra misión es conectar a los
                                agricultores directamente con los consumidores, garantizando los productos más frescos
                                y de mayor calidad y al mismo tiempo apoyando la agricultura local. Creemos en el poder
                                de la comunidad y la sostenibilidad, y nuestra plataforma está diseñada para facilitar que
                                los agricultores, proveedores y conductores prosperen juntos.</h5>
                        </Col>
                    </Row>
                </Container>
                {/* process section starts */}
                <section className="process-us-section common-padding" id="how-it-works">
                    <Container>
                        <div className="choose-under-content">
                            <div className="under-choose-box">
                                <p className="text-justify">
                                    En Semilla, brindamos a los agricultores las herramientas que necesitan para administrar
                                    su inventario y llegar a más clientes, sin interrumpir sus rutas de ventas tradicionales.
                                    Nuestra innovadora tecnología de seguimiento de proveedores garantiza una integración
                                    perfecta, lo que permite a los agricultores centrarse en lo que mejor saben hacer: cultivar
                                    productos frescos y deliciosos.</p>
                                <p className="text-justify mt-3">
                                    Para nuestros clientes, ofrecemos la comodidad de la entrega de productos frescos de la
                                    granja, llevando una amplia variedad de productos locales directamente a su puerta. Y
                                    para nuestros conductores, ofrecemos oportunidades laborales flexibles que se adaptan
                                    a su horario, ayudándolos a ganar dinero mientras apoyan a su comunidad.
                                </p>
                            </div>
                            <img
                                className=""
                                src={require("../assets/images/process.png")}
                                alt="img"
                            />
                            <div className="text-center">
                            </div>
                        </div>
                    </Container>
                </section>
                {/* process section ends */}
                {/* newslater section starts */}
                <section className="newslater-section common-padding" id="contact-us">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={10} md={12}>
                                <img
                                    className="text-center d-block m-auto"
                                    src={require("../assets/images/newslater.png")}
                                    alt="img"
                                />
                                <div className="newslater-box">
                                    <div className="subheading-section mb-4 text-center">
                                        <h2 className="subheading">Únete a nosotras</h2>
                                        <p className="text-justify">Únase a nosotros para revolucionar la forma en que accedemos a alimentos frescos,
                                            apoyamos a los agricultores locales y construimos un futuro más sostenible. Juntos
                                            podemos marcar la diferencia, una entrega a la vez.</p>
                                    </div>
                                    {/* <Form className="form-newslater">
                                        <Form.Control type="email" placeholder="Introducir la dirección de correo electrónico" />
                                        <Button variant="primary" type="submit" className="green-btn">
                                            Suscribir
                                        </Button>
                                    </Form> */}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
                {/* newslater section ends */}
            </section>
        </>
    );
};
export default AboutUsSpanish;
;
