import { Container, Form } from "react-bootstrap";
import TabComponent from "../common/TabComponent";
import { useEffect, useState } from 'react';
import { apiService } from "../service/api.service";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";
import { errorHandling } from "../config/config"
import {formatToColombianCurrency} from "../config/config"
import Loader from "../common/Loader";
import Pagination from "react-js-pagination";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
const TransactionHistory = () => {
  const { t } = useTranslation();
  const [loader, setLoader] = useState(false)
  const [transactionData, settransactionData] = useState()
  const [page, setpage] = useState(0)
  const [size, setsize] = useState(10)
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalItems, settotalItem] = useState()
  useEffect(() => {
    getTransaction(page, size)
  }, [])
  const handleApply = () => {
    getTransaction(page, size)
    // Do something with startDate and endDate, like applying filters
    console.log("Selected start date:", startDate);
    console.log("Selected end date:", endDate);
  };
  function clear() {
    setStartDate(null)
    setEndDate(null)
    getTransaction(page, size)
  }
  const handleStartDateChange = date => {
    console.log("=====date===", date)
    date = new Date(date)
    console.log("=====date===", date)
    let dt = moment(date).format("YYYY-MM-DD")
    console.log("=====dt===", dt)
    setStartDate(dt);
  }
  const handleEndDateChange = date => {
    date = new Date(date)
    console.log("=====dt===", date)
    let dt = moment(date).format("YYYY-MM-DD")
    console.log("=====dt===", dt)
    setEndDate(dt);
  }
  function handlePageChange(pageNumber) {
    setpage(pageNumber - 1);
    getTransaction(pageNumber - 1, size)
  }
  const getTransaction = (page, size) => {
    setLoader(true)
    let queryParam = ``
    if (startDate && endDate) {
      queryParam = `?from=${startDate}&to=${endDate}`
    }
    apiService.orderTransaction(page, size,queryParam).then((res) => {
      settotalItem(res.data.data.total)
      setLoader(false)
      settransactionData(res.data.data.list)
      console.log("======Get=trans data====>", res.data.data)
     
      // setbankList(res.data.data)

    }).catch((err) => {
      errorHandling(err.response.status)
      setLoader(false)

      // setupdateShow(true)
    });
  }
  return (
    <>
      {loader && <Loader />}
      <div className="height-footer">
        <Container>
          <TabComponent />
          <div className="tab-pane" id="dashboard">
            <div className='dashboard-management common-under-padding  status-bar justify-content-between'>
              <h2 className='subheading-under mb-4'>{t("transaction_history")}</h2>
              <div className='select-box-mobile me-3'>
                <div className="heading-top-area-right date-range-filter">
                <div className="date-time-section">
                  <div className="inner-div">
                    <label>{t("from")}:</label>
                    <div className="pick-date">
                    <DatePicker
                      selected={startDate}
                      onChange={date => handleStartDateChange(date)}
                      selectsStart
                      startDate={startDate}
                      endDate={endDate}
                      placeholderText={t("from")}
                    // dateFormat="YYYY-MM-DD"
                      />
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      </div>
                      </div>
                      <div className="inner-div">
                    <label>{t("to")}:</label>
                    <div className="pick-date">
                    <DatePicker
                      selected={endDate}
                      onChange={date => handleEndDateChange(date)}
                      // selectsEnd
                      // startDate={startDate}
                      endDate={endDate}
                      minDate={startDate}
                      placeholderText={t("to")}
                    //  dateFormat="YYYY-MM-dd"
                    />
                      <i class="fa fa-calendar" aria-hidden="true"></i>
                      </div>
                      </div>
                    </div>
                  <button onClick={handleApply} className="apply-top-btn">{t("apply")}</button>
                  <button onClick={clear} className="apply-top-btn clear-top-btn">{t("clear")}</button>
                </div>
              </div>
            </div>
            <div className="transaction_history_boxes">
              <div className='table-data'>
                <table class="table">
                  <thead>
                    <tr>
                      <th>{t("date")}</th>
                      <th>{t("transaction_id")}</th>
                      <th>{t("gross_amt")}</th>
                      <th> {t("platform_fee")}</th>
                      <th> {t("earnings")}</th>

                      <th>{t("status")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transactionData && transactionData.map((data, index) => {

                      return (
                        <tr>
                          <td><p>{moment(data?.transactionDate).format("DD MMMM, YYYY")}</p> </td>
                          <td>
                            <a href="#" className="invoice">{data.transactionId}</a>
                          </td>
                          <td>
                            ${
                              data.grossAmount ? formatToColombianCurrency(data.grossAmount) : 0
                            }
                          </td>
                          <td>
                            <p className='red-text'><span >-</span> ${data.platformFee ? formatToColombianCurrency(data.platformFee) : 0}</p>

                          </td>
                          <td>
                            ${data.netAmount ? formatToColombianCurrency(data.netAmount) : 0}
                          </td>
                          <td>
                            {data.status == "SUCCESS" &&
                              <p className="receive-status">{t("recieved")}</p>}
                            {/* <p className="transfer-status"></p> */}
                            {/* <p className="transfer-status">Deducted</p> */}
                          </td>
                        </tr>)
                    })}

                  </tbody>
                </table>
              </div>
            </div>
            {transactionData && transactionData.length == 0 &&
                                <div className="nodata centre">
                                    <img className="" src={require("../assets/images/no-data-found.png")} alt="img"
                                    />
                                    <span>{t("no_data_found")}</span>
                                </div>

                            }
          </div>
          {transactionData && transactionData.length > 0 && totalItems > size ? (
            <div className="text-right">
              <div className='pagination-tab'>
                <Pagination
                  activePage={page + 1}
                  itemsCountPerPage={size}
                  totalItemsCount={totalItems}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                //   prevPageText={"Prev"}
                //   nextPageText={"Next"}
                />
              </div>
            </div>
          ) : null}
        </Container>
      </div>
    </>
  );
};
export default TransactionHistory;
