import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { Link, useNavigate  } from "react-router-dom";
import { useState } from "react";
import { validateService} from "../service/validation.service";
import { apiService} from "../service/api.service";
import Loader from "../common/Loader";
import swal from "sweetalert";
import {baseUrl} from "../config/config"
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n, { changeLanguage } from 'i18next';

import '../i18n';
const Login = () => {
  const { t } = useTranslation();
  function CreateProfile() {
    window.location.href = "/create-profile"
    // history("/create-profile");
  }
  const [loader,setLoader]=useState(false)
  const [showPass,setshowPass]=useState(false)
  const [loginFields, setloginFields] = useState({
    email: "",
    password: "",
    role:"cultivator"
   });
   const [loginFieldsErrs, setloginFieldsErrs] = useState({
    email: "",
    password: "",
   });
   const onInputChange = (field, value) => {
    setloginFields({
        ...loginFields,
        [field]: value,
      });
   
    };
   

    // const handleLogin = (id) => {
    //   history.push('/home', { scrollTo: id });
    // };

   const handleSubmit = () => {

    // e.preventDefault();
   let formValidated =  validateService.login(setloginFieldsErrs,loginFields)
    if (formValidated) {
      setLoader(true)
      apiService.login(loginFields).then((res) => {
         console.log(res)
         
        //  return
         setLoader(false)
        localStorage.setItem("web_token",res.data.data.token)
        localStorage.setItem("user",res.data.data.user.id)
        localStorage.setItem("profile_status",res.data.data.user.status)
        localStorage.setItem("stripeCustomerId",res.data.data.user.stripeCustomerId)
        localStorage.setItem("stripeBankId",res.data.data.user.payuBankAdded)
        localStorage.setItem("online_status", res.data.data.user.online);
        if(localStorage.getItem("lang")!=="null"){
          changeLanguage(localStorage.getItem("lang"))
          localStorage.setItem("lang", localStorage.getItem("lang"));
        }else{
          localStorage.setItem("lang", res.data.data.user.lang);
          
        }
        
        if(res.data.data.user.imagePath){
          localStorage.setItem("profile_img",baseUrl+res.data.data.user.imagePath)
        }
      
        
         setloginFields({
                role:"cultivator",
                email: "",
                password: "",
            });
            setloginFieldsErrs({
                email: "",
                emailErr:"",
                password: "",
                passwordErr:"",
               
              });
              if(res.data.data.name != ""){
                 window.location.href = "/dashboard"
                // history("/dashboard");
              }else{
                CreateProfile()
              }
     }).catch((err) => {
          setLoader(false)
          console.log(err)
          swal("Error",err.response.data.message,"error")
         console.log("contact us api error", err.response);
        });
    }


  }
  const changeLanguage=(lng)=>{
    apiService.changeLanguage(lng).then((res) => {
    }).catch((err) => { 
      // setLoader(false)
   
      // setupdateShow(true)
    });
  }
  
  return (
    <>
      <section className="login-section">
      {loader && <Loader/>}
        <Container>
          <Row>
            <Col lg={6} md={8}>
              <div className="login-form-box">
                <div className="login-header">
                  <h2>{t('sign_in')} </h2>
                  <p>{t('use_email')}</p>
                </div>
                <Form className="login-body">
                  <Form.Group className="mb-3">
                    <Form.Label>{t('email_place')}</Form.Label>
                    <Form.Control type="email" placeholder={t('enter_email')} onChange={(e)=>onInputChange("email", e.target.value)}/>
                    <span className="text-danger">{t(loginFieldsErrs.email)}</span>
                  </Form.Group>
                  <Form.Group className="mb-3 position-relative">
                    <Form.Label>{t('password')}</Form.Label>
                    <Form.Control type={showPass?"text":"password"} placeholder={t('password_place')}  onChange={(e)=>onInputChange("password", e.target.value)}/>
                    {showPass?
                    <img
                    className="eye-icon"
                    src={require("../assets/images/eye-crossed 1.svg").default}
                    onClick={()=>setshowPass(false)}
                    alt="img"
                  />:
                    <img
                      className="eye-icon"
                      onClick={()=>setshowPass(true)}
                      src={require("../assets/images/eye (1).svg").default}
                      alt="img"
                    />}
                    {/* <img
                  className="eye-icon"
                  src={require("../assets/images/eye-crossed 1.svg").default}
                  alt="img"
                /> */}
                    <span className="text-danger">{t(loginFieldsErrs.password)}</span>
                  </Form.Group>
                  <Link to="/forgot-password" className="forgot-password-txt">{t('forgot_pass')}</Link>
                  <Button onClick={handleSubmit} className="green-btn">{t('sign_in')}</Button>
                  <p>{t('donot_acc')} <Link to="/signup" className="">{t('signup')}</Link></p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default Login;
