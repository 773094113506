import React from 'react';
import { Chart as ChartJS } from 'chart.js/auto'
import { Bar }            from 'react-chartjs-2'
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
import {formatToColombianCurrency} from "../config/config"
const BarChart = ({labels,values}) => {
console.log("=====labels=====",labels)
const { t } = useTranslation();
  // Your chart data and options
  const data = {
    labels: labels,
    datasets: [
      {
        label: t('chart_label'),
        data: values,
       backgroundColor: '#00A850',
            borderColor: '#00A850',
          borderWidth: 0.1,
            barThickness: 13,
            borderRadius: 12      },
    ],
  };

  const options = {
    scales: {
      y: {
        type: 'linear', // specify the scale type explicitly
        beginAtZero: true,
        ticks: {
          // Use callback function to prepend dollar sign to each tick value
          callback: function (value, index, values) {
            return '$' + formatToColombianCurrency(value);
          },
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: t('chart_heading'), // Title text
        font: {
          size: 20, // Title font size
        },
      },
      tooltip: {
        callbacks: {
          // Use callback function to prepend dollar sign to each tooltip label
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += '$' + formatToColombianCurrency(context.parsed.y); // Append dollar sign
            }
            return label;
          },
        },
      },
    },
  };

  return <Bar data={data} options={options} />;
};

export default BarChart;
