import React, { useEffect, useState } from 'react';

import { GoogleMap, LoadScript, Marker, DirectionsService, DirectionsRenderer } from '@react-google-maps/api';
import { baseUrl, errorHandling, gKey, socketUrl, apiUrl,weburl } from "../../../config/config"
import { Stomp } from '@stomp/stompjs';
import { useParams } from "react-router-dom";
import { apiService } from "../../../service/api.service";
import Loader from "../../../common/Loader";
import { Button, Col, Container, Row } from "react-bootstrap";
import swal from 'sweetalert';
const mapContainerStyle = {
  width: '100%',
  height: '100vh',
};

const center = {
  lat: 0,
  lng: 0,
};

const TrackOrder = () => {
  const [loader, setLoader] = useState(false)
  const [driverLocation, setDriverLocation] = useState(center);
  const [destinations, setdestinations] = useState(center);
  const [map, setMap] = useState(null);
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [infoWindow, setInfoWindow] = useState(null);
  const [destinationTitle, setdestinationTitle] = useState("You");
  const [orderStatus, setorderStatus] = useState();
  const origin = 'New York, NY'; // Example origin address

  let props = useParams()
  //   /subscribe/location/{orderId}

  // const destinations = { lat: 12.9716, lng: 77.5946 }; //
  useEffect(() => {
    GetOrderDetail()
    // Set up WebSocket connection
    const client = Stomp.client(socketUrl);
    //
    client.connect({}, () => {

      client.subscribe('/subscribe/location/' + props.orderId, (message) => {
        GetOrderDetail()
        //    setorderStatus(getStatus(message.body.status))
        console.log(message.body)
        setdestinations({
          lat: message.body.latitude,
          lng: message.body.longitude,
        })
      });

      client.onStompError = (error) => {
        console.error('STOMP Error:', error);
      };

      // Optional: Add debug logging
      client.debug = (msg) => {
        console.log('STOMP Debug:', msg);
      };

      // Clean up function
      return () => {
        client.disconnect();
      };
    })
   

  }, []);
  const openInGoogleMap = ()=>{
    // const origin = 'New York, NY';
    // const destination = 'Los Angeles, CA'; // Example destination address
    const originLat =driverLocation.lat; // Example origin latitude
const originLng = driverLocation.lng; // Example origin longitude
const destinationLat = destinations.lat; // Example destination latitude
const destinationLng = destinations.lng; // Example destination longitude

// Construct the Google Maps URL with origin and destination parameters
const googleMapsUrl = `https://www.google.com/maps/dir/?api=1&origin=${originLat},${originLng}&destination=${destinationLat},${destinationLng}`;

// Open the Google Maps URL in a new browser window
window.open(googleMapsUrl, '_blank');
  
  }

  const GetOrderDetail = () => {
    apiService.getOrderDetail(props.orderId).then((res) => {
      console.log("order====", res.data.data.order)
      console.log("order====", res.data.data.order.status)
      console.log("driver====", res.data.data.order.driver)
      setorderStatus(res.data.data.order.status)
      console.log("deliveryAddress====", res.data.data.order.deliveryAddress)
      setDriverLocation({
        lat: res.data.data.order.driver?.latitude,
        lng: res.data.data.order.driver?.longitude,
      })
    if(res.data.data.order.status=="PICKUP_PARTNER_VERIFIED" || res.data.data.order.status=="OUT_FOR_DELIVERY" ){
        setdestinationTitle("Customer")
        setdestinations({
          lat: res.data.data.order.deliveryAddress.latitude,
          lng: res.data.data.order.deliveryAddress.longitude,
        })
      }else{
        setdestinationTitle("You")
        setdestinations({
          lat: res.data.data.order.cultivator.latitude,
          lng: res.data.data.order.cultivator.longitude,
        })
      }
    
        
      
    


      setLoader(false)
    }).catch((err) => {
      setLoader(false)
      // errorHandling(err.response.status)

      // console.log(err)
      console.log("contact us api error", err.response);
    });
  }
  useEffect(() => {
    // Load Google Maps API script dynamically
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=` + gKey;
    script.async = true;
    script.onload = initializeMap;
    document.body.appendChild(script);
    
    // Clean up
    return () => {
      document.body.removeChild(script);
    };
    
  }, []);

  const initializeMap = () => {
    const google = window.google;
    setInfoWindow(new window.google.maps.InfoWindow());
    const map = new google.maps.Map(document.getElementById('map'), {
      zoom: 12,
      center: center,
    });
    const directionsService = new google.maps.DirectionsService();
    const directionsRenderer = new google.maps.DirectionsRenderer();
    setMap(map);
    setDirectionsService(directionsService);
    setDirectionsRenderer(directionsRenderer);
  };

  useEffect(() => {
    if (driverLocation.lat !== 0 && map && directionsService && directionsRenderer) {
      calculateDirections();
    }
  }, [driverLocation, map, directionsService, directionsRenderer]);



  const calculateDirections = () => {
    const origin = new window.google.maps.LatLng(driverLocation.lat, driverLocation.lng);
    const destination = new window.google.maps.LatLng(destinations.lat, destinations.lng);
    directionsService.route(
      {
        origin: origin,
        destination: destination,
        
        // waypoints: [
        //   { location: new window.google.maps.LatLng({ lat: driverLocation.lat, lng:driverLocation.lng }), label: 'Driver' },
        //   { location: new window.google.maps.LatLng({ lat: destinations.lat, lng:destinations.lng }), label: destinationTitle }
        // ],
        // travelMode: 'DRIVING'
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        console.log("====result===",result)
        if (status === window.google.maps.DirectionsStatus.OK) {
          const directionsRenderer = new window.google.maps.DirectionsRenderer({
            map: map,
            suppressMarkers: true,
            markerOptions: {
              icon: {
                url: 'https://maps.google.com/mapfiles/kml/pal2/icon32.png',
                scaledSize: new window.google.maps.Size(32, 32)
              }
            }
          });
          directionsRenderer.setDirections(result);
          directionsRenderer.setMap(map);
          const originLabel = 'Driver';
          const destinationLabel = destinationTitle;
          const originMarker = new window.google.maps.Marker({
            position: result.routes[0].legs[0].start_location,
            label: { text:".", color: 'black',fontSize: "1px", fontWeight:"999" },
            icon: {
              url: weburl+'bike.png',
              scaledSize: new window.google.maps.Size(32, 32) // Adjust the size as needed
          },
            map: map
          });
          const destinationMarker = new window.google.maps.Marker({
            position: result.routes[0].legs[0].end_location,
            label: { text: ".", color: 'black',fontSize: "1px", fontWeight:"999"},
            icon: {
              url: weburl+'home.png',
              scaledSize: new window.google.maps.Size(32, 32) // Adjust the size as needed
          },
            map: map
          });


          originMarker.addListener('mouseover', () => {
            getInfoWindowContent(result.routes[0].legs[0].start_location, (address) => {
              infoWindow.setContent("<h6>"+originLabel+":</h6>"+address);
              infoWindow.open(map, originMarker);
            });
          });
          originMarker.addListener('mouseout', () => {
            infoWindow.close();
          });
          destinationMarker.addListener('mouseover', () => {
            getInfoWindowContent(result.routes[0].legs[0].end_location, (address) => {
              infoWindow.setContent("<h6>"+destinationLabel+":</h6>"+address);
              infoWindow.open(map, destinationMarker);
            });
          });
          destinationMarker.addListener('mouseout', () => {
            infoWindow.close();
          });
          // const startMarker = new window.google.maps.Marker({
          //   position: origin,
          //   map: map,
          //   label: {
          //     text: 'Driver',

          //     backgroundcolor: "#00000", /* Background color of the label */
          //     border: "1px solid #000000", /* Border color and width */
          //     color: "#000", /* Text color */
          //     fontSize: "7px", /* Font size of the label text */
          //     padding: "4px 8px", /* Padding around the label text */
          //     borderColor: "4px" ,/* Border radius to round the corners */
          //     fontWeight:"999"
          //   },
          //   zIndex: 1000,
          //   // title: 'Start: Chicago',

          // });
          // const endMarker = new window.google.maps.Marker({
          //   position: destination,
          //   map: map,
          //   label: {
          //     text: destinationTitle,
              
          //     backgroundcolor: "#ffffff", /* Background color of the label */
          //     border: "1px solid #000000", /* Border color and width */
          //     color: "#000", /* Text color */
          //     fontSize: "6.3px", /* Font size of the label text */
          //     padding: "10px 10px", /* Padding around the label text */
          //     borderColor: "4px", /* Border radius to round the corners */
          //     fontWeight:"999"
          //   },
          //   zIndex: 1000,
          //   // title: 'Customer',
          // });
        } else {
          console.error(`Directions request failed due to ${status}`);
        }
      }
    );
  };
// Function to fetch address information using Geocoding API
const getInfoWindowContent = (location, callback) => {
  const geocoder = new window.google.maps.Geocoder();
  geocoder.geocode({ location: location }, (results, status) => {
    if (status === 'OK') {
      if (results[0]) {
        const address = results[0].formatted_address;
        callback(address);
      } else {
        callback('Address not found');
      }
    } else {
      callback('Geocoder failed due to: ' + status);
    }
  });
};
const GotoHome =()=>{
  window.location.href = "/dashboard"
}
  return <>
    <div className="height-footer">
      {orderStatus && orderStatus=="ORDER_DELIVERED"?
        <Container> <Row className="justify-content-center">
       <Col md={6}>
         {/* could not verify your account details section start */}
         <div className="verify-content-box text-center">
      
           <img src={require("../../../assets/images/submit-successfully.png")} />
          
        <h5 className="mt-3">This order has been delivered. </h5> 
         <Button className="join-us-now-btn mt-4" onClick={()=> {GotoHome()}}>Go to Home</Button>
         </div>
        
       </Col>
     </Row></Container>:
     <>
     <button className='green-btn btn text-light ms-3 map-button' onClick={openInGoogleMap}>Open In Google Map</button>
        <div id="map" style={mapContainerStyle}>
        </div></>
        }
    </div>
  </>;
};



export default TrackOrder;
