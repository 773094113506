import { useState, useEffect } from 'react';
import { Button, Col, Container, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import TabComponent from "../../../common/TabComponent";
import { apiService } from "../../../service/api.service";
import { baseUrl, errorHandling } from "../../../config/config"
import Loader from "../../../common/Loader";
import Pagination from "react-js-pagination";
import swal from 'sweetalert';
import moment from "moment";
const HarvestTracking = () => {


   

    return (<>
        <div className="height-footer">
            {/* {loader && <Loader />} */}
            <Container>
                <TabComponent />
                <div className="tab-pane" id="product-management">
                    <div className='product-management common-under-padding'>
                        <div className='product-header-area status-bar'>
                            {/* <h2 className='subheading-under'>Harvest Tracking</h2> */}
                            <div className='under-heading'>
                                <div className='search-box position-relative'>
                              </div>
                                <div className='select-box-mobile me-3'>
                                   
                                </div>
                              
                            </div>
                        </div>
                        <div className='table-data'>
                          
                          
                                <div className="nodata centre">
                                    {/* <img className="" src={require("../../../assets/images/no-data-found.png")} alt="img"
                                    /> */}
                                    <span><h3>Coming Soon</h3></span>
                                </div>

                            

                        </div>
                   
                    </div>
                </div>
            </Container>
        </div>
    </>)
}

export default HarvestTracking;