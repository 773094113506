
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from 'swiper/modules';
import "swiper/css";
import 'swiper/css/navigation';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';

import { Link, useNavigate,useLocation  } from "react-router-dom";


const Home = () => {
    const { t } = useTranslation();
    const location = useLocation();
    let hash = location.hash.split('#');
    

    let navigate = useNavigate();

    if(hash && hash[1]){
        setTimeout(()  =>{ 
            const section = document.getElementById(hash[1]);
    
            if (section) {
              section.scrollIntoView({ behavior: 'smooth' });
            } 
        },2000)
        
    }
 
    return (
        <>
            {/* hero banner section starts */}
            <section className="hero-bannner-section">
                <Container>
                    <Row className="align-items-center">
                        <Col md={12} lg={6}>
                            <div className="hero-left-content">
                          
                                <h1>{t('home_maximize')}</h1>
                                <h3>{t('home_maximize_sub')}</h3>
                                <p>{t('maximize_content')}</p>
                                <Button className="join-us-now-btn" onClick={()=>navigate("/contact-us")}>{t("cta_button")}</Button>
                            </div>
                        </Col>
                        <Col md={12} lg={6}>
                            <div className="hero-left-img">
                                <img
                                    className=""
                                    src={require("../assets/images/right-man-img.png")}
                                    alt="img"
                                />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* hero banner section ends */}
            {/* why choose section starts */}
            <section className="choose-us-section common-padding" id="about-us">
                <Container>
                    <div className="subheading-section text-center mb-3">
                        <h2 className="subheading">{t("build_future")}</h2>
                        <h4 className="under-subheading">{t("build_future_sub")}</h4>
                    </div>
                    <div className="choose-under-content text-center">
                        <p>{t("build_future_content")}</p>
                        <img
                            className=""
                            src={require("../assets/images/choose-img.png")}
                            alt="img"
                        />
                          <Button className="join-us-now-btn" onClick={()=>navigate("/contact-us")}>{t("build-cta_btn")}</Button>
                    </div>
                </Container>
            </section>
            {/* why choose section ends */}
            {/* service offer section starts */}
            <section className="service-section common-padding">
                <Container>
                    <div className="subheading-section text-center mb-3">
                        <h2 className="subheading text-black">{t("our_service")}</h2>
                        {/* <h4 className="under-subheading">{t("our_service")}</h4> */}
                    </div>
                    <Row className="mt-5">
                        <Col lg={3} md={6} xs={6}> 
                            <div className="service-inner">
                                {/* <h2>{t("product_available")}</h2> */}
                                <h5>{t("product_available")}</h5>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={6}>
                            <div className="service-inner border-0 box-shadow-none">
                                {/* <h2>+24</h2> */}
                                <h5>{t("active_users")}</h5>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={6}>
                            <div className="service-inner border-0 box-shadow-none">
                                {/* <h2>+44</h2> */}
                                <h5>{t("active_farmers")}</h5>
                            </div>
                        </Col>
                        <Col lg={3} md={6} xs={6}>
                            <div className="service-inner right">
                                {/* <h2>+54</h2> */}
                                <h5>{t("revenue")}</h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* service offer section ends */}
            {/* process section starts */}
            <section className="process-us-section common-padding" id="how-it-works">
                <Container>
                    <div className="subheading-section text-center mb-3">
                        <h2 className="subheading">{t("selling")}</h2>
                        <h4 className="under-subheading">{t("how_work")}</h4>
                    </div>
                    <div className="choose-under-content">
                        {/* <ul> */}
                            <div className="under-choose-box">
                                <p>{t("li1")}</p>
                                <p>{t("li2")}</p>
                                <p>{t("li3")}</p>
                                <p>{t("li4")}</p>
                                <p>{t("li5")}</p>
                                <p>{t("li6")}</p>
                            </div>
                            {/* </ul> */}
                        <img
                            className=""
                            src={require("../assets/images/process.png")}
                            alt="img"
                        />
                        <div className="text-center">
                            <Button className="join-us-now-btn" onClick={()=>navigate("/login")}>{t("cta_btn")}</Button>
                         </div>
                    </div>
                </Container>
            </section>
            {/* process section ends */}
            {/* cultivators section starts */}
            <section className="cultivatorss-section common-padding">
                <Container>
                    <div className="subheading-section text-center mb-3">
                        <h2 className="subheading">{t("happy_culti")}</h2>
                        <h4 className="under-subheading">{t("happy_culti_sub")}</h4>
                    </div>
                    <div className="testimonial-box">
                        <Row className="align-items-center">
                            <Col lg={4}>
                                <div className="cultivatorss-img">
                                    <img
                                        className=""
                                        src={require("../assets/images/culti-man.png")}
                                        alt="img"
                                    />
                                </div>
                            </Col>
                            <Col lg={8}>

                                <div className="cultivatorss-content">
                                    <img
                                        className=""
                                        src={require("../assets/images/quote.png")}
                                        alt="img"
                                    />
                                    <Swiper
                                        navigation={true} modules={[Navigation]}
                                        slidesPerView={1}
                                    >
                                        <SwiperSlide>
                                            <div className="cultivator-content">
                                                <p>{t("review1")}
                                                </p>
                                                <h6>{t("review1_cust")}</h6>
                                                <div className="star-box">
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide>
                                            <div className="cultivator-content">
                                            <p>{t("review2")}
                                                </p>
                                                <h6>{t("review2_cust")}</h6>
                                                <div className="star-box">
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        {/* <SwiperSlide>
                                            <div className="cultivator-content">
                                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry'sstandard dummy text ever since
                                                    the 1500s, when an unknown printer took a galley of type and scrambled it.
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry.
                                                    Lorem Ipsum has been the industry'sstandard dummy text ever since the 1500
                                                </p>
                                                <h6>Adam Smith</h6>
                                                <div className="star-box">
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                    <i className="fa-solid fa-star"></i>
                                                </div>
                                            </div>
                                        </SwiperSlide> */}
                                    </Swiper>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
            {/* cultivators section ends */}
            {/* our marketplace section starts */}
            <section className="marketplace-section common-padding">
                <Container>
                    <Row className="align-items-center">
                        <Col lg={6} md={12}>
                            <div className="marketplace-img-box">
                                <img
                                    className=""
                                    src={require("../assets/images/marketplace.png")}
                                    alt="img"
                                />
                            </div>
                        </Col>
                        <Col lg={6} md={12}>
                            <div className="marketplace-content">
                                <div className="subheading-section mb-3">
                                    <h2 className="subheading">{t("our_marketplace")}</h2>
                                    {/* <h4 className="under-subheading">in your pocket</h4> */}
                                </div>
                                <p>{t("discover")}</p>
                                <div className="play-store-box">
                                    <a href="#" className="apple-btn">
                                        <img
                                            className=""
                                            src={require("../assets/images/apple.svg").default}
                                            alt="img"
                                        />
                                        Apple store
                                    </a>
                                    <a href="#" className="apple-btn play-btn">
                                        <img
                                            className=""
                                            src={require("../assets/images/paly.svg").default}
                                            alt="img"
                                        />
                                        Play store
                                    </a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* our marketplace section ends */}
            {/* newslater section starts */}
            <section className="newslater-section common-padding" id="contact-us">
                <Container>
                    <Row className="justify-content-center">
                        <Col lg={10} md={12}>
                            <img
                                className="text-center d-block m-auto"
                                src={require("../assets/images/newslater.png")}
                                alt="img"
                            />
                            <div className="newslater-box">
                                <div className="subheading-section mb-4 text-center">
                                    <h2 className="subheading">Join Our Newsletter</h2>
                                    <h4 className="under-subheading">Get timely updates from your products</h4>
                                </div>
                                <Form className="form-newslater">
                                    <Form.Control type="email" placeholder="Enter Email Address" />
                                    <Button variant="primary" type="submit" className="green-btn">
                                        Subscribe
                                    </Button>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* newslater section ends */}
        </>
    );
};
export default Home;
