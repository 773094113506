import { useState, useEffect } from "react";
import { Col, Container, Row, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import TabComponent from "../../common/TabComponent";
import { apiService } from "../../service/api.service";
import { getStatus, socketUrl, errorHandling ,formatToColombianCurrency,timeRemaining} from "../../config/config";
import moment from "moment";
import BarChart from "../../common/Graph";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../../i18n';
import { Stomp } from '@stomp/stompjs';
import TimerComponent from './timerComponent.js';
const Dashboard = () => {
  const { t } = useTranslation();
  const [orders, setorderData] = useState();
  const [orderCounts, setorderCounts] = useState();
  const [labels, setlabels] = useState([]);
  const [values, setvalues] = useState([]);
  const [responseTime, setresponseTime] = useState();
  const [timeLeft, settimeLeft] = useState()
  useEffect(() => {
    // Set up WebSocket connection
    const client = Stomp.client(socketUrl);
    //
    client.connect({}, () => {
        // /subscribe/user/{identifier}'
        console.log('/subscribe/user/' + localStorage.getItem("user"))
        client.subscribe('/subscribe/user/' + localStorage.getItem("user"), (message) => {
          console.log('event====',message)
          getOrdersDashboard();
          getCountDashboard();
        });

        client.onStompError = (error) => {
            console.error('STOMP Error:', error);
        };

        // Optional: Add debug logging
        client.debug = (msg) => {
            console.log('STOMP Debug:', msg);
        };

        // Clean up function
        return () => {
            client.disconnect();
        };
    })


}, []);
  useEffect(() => {
    getOrdersDashboard();
    getCountDashboard();
  }, []);
  const tooltip = <Tooltip id="tooltip"> {t("view_tooltip")}</Tooltip>;
  const getOrdersDashboard = () => {
    apiService
      .getOrdersDashboard()
      .then((res) => {
        setresponseTime(res.data.data.responseTime)
        setorderData(res.data.data.list);

      })
      .catch((err) => {
        // setLoader(false)
        errorHandling(err.response.status)

        // console.log(err)
        console.log("contact us api error", err.response);
      });
  };


  const getCountDashboard = () => {
    apiService
      .dashboardCount()
      .then((res) => {
        console.log(res.data.data);
        setorderCounts(res.data.data.counts);
        const newValues = [];
        const newLabels = [];
        res.data.data.counts.earningGraph.map((data) => {
          console.log("=====data.newValues====,", data.
          value
        )
          newValues.push(data.value);



          // Update the state with the new array



          console.log("=====data.newValues====,", data.
            label
          )

          newLabels.push(data.label);

          console.log("=====newLabels====,", newLabels)
          // Update the state with the new array



        })
        setvalues(newValues);
        setlabels(newLabels);
        //    setorderData(res.data.data.list)
      })
      .catch((err) => {
        // setLoader(false)
        errorHandling(err.response.status)

        // console.log(err)
        console.log("contact us api error", err.response);
      });
  };

  const graphData = [
    { label: "January", value: 20 },
    { label: "February", value: 25 },
    { label: "March", value: 30 },
    { label: "April", value: 35 },
    { label: "May", value: 20 },
    { label: "June", value: 25 },
    { label: "July", value: 30 },
    { label: "August", value: 35 },
    { label: "September", value: 20 },
    { label: "October", value: 25 },
    { label: "November", value: 30 },
    { label: "December", value: 35 },
  ];

  return (
    <>
      <div className="height-footer">
        <Container>
          <TabComponent />
          <div className="tab-pane" id="dashboard">
            <div className="dashboard-management common-under-padding">
              <h2 className="subheading-under mb-4">{t("dashboard")}</h2>
              <div className="boxes-section">
                <Row>
                  <Col sm={4}>
                    <div className="dashboard-card">
                      <img
                        className=""
                        src={require("../../assets/images/badge-1.svg").default}
                        alt="img"
                      />
                      <h5>{t("total_sale")}</h5>
                      <h3 className="dashboard-txt">
                        {orderCounts?.sales ? formatToColombianCurrency(orderCounts?.sales) : 0}
                        {/* <span className='span-dashboard'>+10%</span> */}
                      </h3>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="dashboard-card dashboard-card2">
                      <img
                        className=""
                        src={require("../../assets/images/badge-2.svg").default}
                        alt="img"
                      />
                      <h5>{t("total_orders")}</h5>
                      <h3 className="dashboard-txt">
                        {orderCounts?.orders}
                        {/* <span className='span-dashboard'>+15%</span> */}
                      </h3>
                    </div>
                  </Col>
                  <Col sm={4}>
                    <div className="dashboard-card dashboard-card3">
                      <img
                        className=""
                        src={require("../../assets/images/badge-3.svg").default}
                        alt="img"
                      />
                      <h5>{t("total_product")}</h5>
                      <h3 className="dashboard-txt">
                        {orderCounts?.products}{" "}
                      </h3>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="graph-section">
                <div>
                  <BarChart labels={labels} values={values} />
                </div>
                {/* graph here added */}
              </div>
              <div className="order-section">
                <h5 className="latest-order-txt">{t("pending_orders")}</h5>
                {/* project management table start */}
                <div className="table-data">
                  <table class="table">
                    <thead>
                      <th>{t("orderid")}</th>
                      <th>{t("order_date")}</th>
                      <th>{t("customer_name")}</th>
                      <th>{t("driver_name")}</th>
                      <th>{t("status")}</th>
                      <th>{t("amount")}</th>
                      <th>{t("action")}</th>
                    </thead>
                    <tbody>
                      {orders &&
                        orders.map((data, i) => {
                          let status = getStatus(data.status);
                          let time = timeRemaining(data.orderDate,responseTime)
                    
                          return (<tr>
                            <td>#{data.orderId}</td>
                            <td>{moment(data.orderDate).format("DD MMMM, YYYY")}</td>
                            <td>{data.customer.fullName}</td>
                            <td>{data.driverName ? data.driverName : "N/A"}</td>
                            <td><span className="delivered pending"></span> {status} </td>
                            <td>${formatToColombianCurrency(data.totalAmount)}</td>
                            <td>
                             <TimerComponent data={data} getOrdersDashboard={getOrdersDashboard} getCountDashboard={getCountDashboard} type={"Dashboard"} responseTime={responseTime} time={time}/>
                            </td>
                          </tr>
                          );
                        })}
                    </tbody>
                  </table>
                  {orders && orders.length == 0 && (
                    <div className="nodata centre">
                      <img
                        className=""
                        src={require("../../assets/images/no-data-found.png")}
                        alt="img"
                      />
                      <span>{t("no_data_found")}</span>
                    </div>
                  )}
                </div>
                {/* project management table end */}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Dashboard;
