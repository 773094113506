import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { validateService} from "../service/validation.service";
import { apiService} from "../service/api.service";
import Loader from "../common/Loader";
import { useEffect, useState } from 'react';
import swal from "sweetalert";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
const ForgotPassword = () => {
  const { t } = useTranslation();
  const [loader,setLoader]=useState(false)
  const [email, setemail] = useState("");
  const [emailErr, setemailErr] = useState();
  let history = useNavigate();

  const forgotPassword = ()=>{
   let valid =  validateService.validateEmail(email,setemailErr)
    if(valid){
      setLoader(true)
      
     apiService.forgotPassword(email).then((res) => {
      setemail("")
  
       swal(t("success"),res.data.message,"success")
      
      
       setLoader(false)
   }).catch((err) => {
    setLoader(false)
    console.log(err)
    swal("Error",err.response.data.message,"error")
   console.log("contact us api error", err.response);
   });
    }
   }
  return (
    <>
      <section className="login-section">
      {loader && <Loader/>}
        <Container>
          <Row>
            <Col lg={6} md={8}>
              <div className="login-form-box">
                <div className="login-header">
                  <h2>{t("forgot_pass")} </h2>
                  <p>{t("forgot_heading")}</p>
                </div>
                <Form className="login-body">
                  <Form.Group className="mb-3">
                    <Form.Label>{t("email_place")}</Form.Label>
                    <Form.Control type="email" placeholder={t("enter_email")} value={email} onChange={(e)=>setemail(e.target.value)}/>
                    <span className="text-danger">{t(emailErr)}</span>
                  </Form.Group>
                  <Button onClick={forgotPassword} className="green-btn" >{t("continue")}</Button>
                  <p>{t("back_to")} <Link to="/login" className="">{t("sign_in")}</Link></p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default ForgotPassword;
