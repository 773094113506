import {  Container } from 'react-bootstrap';
import TabComponent from '../common/TabComponent';

const ProjectManagement = () => {
  return (
    <>
      <div className="height-footer">
        <section className='project-managent-section common-padding'>
          <Container>
             <TabComponent />
          </Container>
        </section>
      </div>
    </>
  );
};
export default ProjectManagement;
