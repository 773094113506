import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
import { useParams } from "react-router-dom";
const NoHeader = () => {
    let props = useParams()
    console.log("====",props.lang)
    if(props.lang){
    
        i18n.changeLanguage(props.lang);
      
      localStorage.setItem("lang",props.lang)
    
     
    }


}
export default NoHeader;