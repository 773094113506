
const Loader = () => {
    return (
        <>
            {/* Loader added */}
            <div className="outer-loader">
                <span class="loader"></span>
            </div>
        </>
    )
};
export default Loader;