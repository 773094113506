import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Switch from 'react-js-switch';
import { apiService } from "../service/api.service";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
import swal from "sweetalert";

const TabComponent = () => {
    const { t } = useTranslation();
    // alert(localStorage.getItem("online_status")=="true")
    const [isSwitchOn, setIsSwitchOn] = useState(localStorage.getItem("online_status")=="true"?true:false);
    const [active, setActive] = useState(window.location.pathname);
    const navigate = useNavigate();
   
    const redirectTo = (url, type) => {
        setActive(type)
        navigate(url);
    }
    const switch_onChange_handle = (onoff,type) => {
   
        if(localStorage.getItem("profile_status")=="Accept"){
            setIsSwitchOn(onoff);
            online_offline(onoff,type)

        }else{
            swal(t("error"),t("reject_err"),"error")
         }
    
    };
    const online_offline = (onoff,type) => {
     
       apiService.online_offline(onoff).then((res) => {
            console.log(res)
            if(type=="on"){
                swal(t("success"),t("online_now"),"success")
            }
            localStorage.setItem("online_status",onoff)
          })
          .catch((err) => {
            // setLoader(false)
            console.log("contact us api error", err.response);
          });
      };
      
    return (
        <>
            <div className='common-tabs'>
                <div className="go-status">
                    <span>{t("go_off_on")}</span>
        
                    <Switch 
                    onChange={(e)=>  {!isSwitchOn?
                        switch_onChange_handle(!isSwitchOn,"on")
                        :swal({
                      title:t("confirm"),
                      text: t('are_you_sure_confirm'),
                      icon: 'warning',
                      buttons: [t('no'), t('yes')],
                      dangerMode: true,
                    }).then((confirmed) => {
                      if (confirmed) {
                   
                        switch_onChange_handle(!isSwitchOn,"off")
                        
                      }else{
                        //  setIsSwitchOn(true);
                        // window.location.reload()
                        // alert(e)
                        // switch_onChange_handle(true)
                       
                      }
                    })
                }
                }
             
                value={isSwitchOn}     initialValue={isSwitchOn} size = {50} backgroundColor={{ on: '#00A850', off: '#f9f9f9' }} borderColor={{ on: '#00A850', off: '#e6e6e6' }}/>
                </div>
                <ul className="nav nav-tabs">
                    <li className="nav-item" >
                        <button className={active.includes('dashboard') ? "nav-link active" : "nav-link"} onClick={() => { redirectTo('/dashboard', window.location.pathname) }} id="dashboard-tab" >
                            <img
                                className=""
                                src={require("../assets/images/dashboard.svg").default}
                                alt="img"
                            />
                            {t("dashboard")}</button>
                    </li>
                    <li className="nav-item" >
                        <button className={active.includes('product-mangement') ? "nav-link active" : "nav-link"} onClick={() => { redirectTo('/product-mangement/products', window.location.pathname) }} >
                            <img
                                className=""
                                src={require("../assets/images/project.svg").default}
                                alt="img"
                            />
                            {t("product_mng")}
                          </button>
                    </li>
                    <li className="nav-item" >
                        <button className={active.includes('order-mangement') ? "nav-link active" : "nav-link"} id="order-management-tab" onClick={() => { redirectTo('/order-mangement/order-management-view', window.location.pathname) }} >
                            <img
                                className=""
                                src={require("../assets/images/order.svg").default}
                                alt="img"
                            />
                            {t("order_management")}</button>
                    </li>
                    <li className="nav-item" >
                        <button className={active.includes('harvest-tracking') ? "nav-link active" : "nav-link"} id="harvest-tracking-tab" onClick={() => { redirectTo('/harvest-tracking', window.location.pathname) }} >
                            <img
                                className=""
                                src={require("../assets/images/order.svg").default}
                                alt="img"
                            />
                            {t("harvest_tracking")}
                           </button>
                    </li>
                    <li className="nav-item" >
                        <button className={active.includes('transaction-history') ? "nav-link active" : "nav-link"} id="transactions-tab"
                            onClick={() => { redirectTo('/transaction-history', 'transactions') }}
                        >
                            <img
                                className=""
                                src={require("../assets/images/transaction.svg").default}
                                alt="img"
                            />
                            {t("transaction")}
                           </button>
                    </li>
                </ul>
            </div>
        </>
    )
}

export default TabComponent;