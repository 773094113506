import { Container, Row } from "react-bootstrap";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
import { defaultLanguage } from "../config/config";
const TermsConditions = () => {
  const { t } = useTranslation();
  let selectedlanguage = (localStorage.getItem("lang") && localStorage.getItem("lang")!="null"?localStorage.getItem("lang"):defaultLanguage)

  console.log("translation",selectedlanguage);
  return (
    <>
      <div className="height-footer privacy-policy-section">
        <Container>
          <Row className="justify-content-center mt-3">
            <h2>{t("t&c_head")}</h2>
            <div className="terms-main-section">
            <p className="mt-2 mb-0"><b>{t("t&c_p11")}</b></p>
            <p className="mt-2">{t("t&c_p1")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p12")}</b></p>
            <p className="mt-2">{t("t&c_pp1")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p13")}</b></p>
            <div className="under-list">
              <ul>
                <li>{t("t&c_ppli1")}</li>
                <li>{t("t&c_ppli2")}</li>
                <li>{t("t&c_ppli3")}</li>
                <li>{t("t&c_ppli4")}</li>
              </ul>
            </div>
            <p className="mt-2 mb-0"><b>{t("t&c_p14")}</b></p>
            <p className="mt-2">{t("t&c_pp2")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p15")}</b></p>
            <div className="under-list">
              <ul>
                <li>{t("t&c_ppli11")}</li>
                <li>{t("t&c_ppli12")}</li>
                <li>{t("t&c_ppli13")}</li>
              </ul>
            </div>
            <p className="mt-2 mb-0"><b>{t("t&c_p16")}</b></p>
            <p className="mt-2">{t("t&c_pp3")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p17")}</b></p>
            <p className="mt-2">{t("t&c_pp4")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p18")}</b></p>
            <p className="mt-2">{t("t&c_pp5")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p19")}</b></p>
            <p className="mt-2">{t("t&c_pp6")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p20")}</b></p>
            <div className="under-list">
              <ul>
                <li>{t("t&c_ppli21")}</li>
                <li>{t("t&c_ppli22")}</li>
                <li>{t("t&c_ppli23")}</li>
              </ul>
            </div>
            <p className="mt-2 mb-0"><b>{t("t&c_p21")}</b></p>
            <p className="mt-2">{t("t&c_pp7")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p22")}</b></p>
            <p className="mt-2">{t("t&c_pp8")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p23")}</b></p>
            <p className="mt-2">{t("t&c_pp9")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p24")}</b></p>
            <p className="mt-2">{t("t&c_pp10")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p25")}</b></p>
            <p className="mt-2">{t("t&c_pp11")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p26")}</b></p>
            <p className="mt-2">{t("t&c_pp12")}</p>
            <p className="mt-2 mb-0"><b>{t("t&c_p27")}</b></p>
            <p className="mt-2">{t("t&c_pp13")}</p>
            </div>

            <div className={selectedlanguage ==="es" ?"no-content privacy-txt":"privacy-txt" }>
             <ul className="privacy-txt privacy-txt-li">
              <li>{t("t&c_p1_li1")}</li>
              <li>{t("t&c_p1_li2")}</li>
              <p>{t("t&c_p1_li2.1")}</p>
              <p>{t("t&c_p1_li2.2")}</p>
              <p>{t("t&c_p1_li2.3")}</p>
              <p>{t("t&c_p1_li2.4")}</p>
              <p>{t("t&c_p1_li2.5")}</p>
              <p>{t("t&c_p1_li2.6")}</p>
              <p>{t("t&c_p1_li2.7")}</p>
              <p>{t("t&c_p1_li2.8")}</p>
              <p>{t("t&c_p1_li2.9")}</p>
              <p>{t("t&c_p1_li2.10")}</p>
              <p>{t("t&c_p1_li2.11")}</p>
              <p>{t("t&c_p1_li2.12")}</p>
              <p>{t("t&c_p1_li2.13")}</p>
              <p>{t("t&c_p1_li2.14")}</p>
              <p>{t("t&c_p1_li2.15")}</p>
              <p>{t("t&c_p1_li2.16")}</p>
              <p>{t("t&c_p1_li2.17")}</p>
              <p>{t("t&c_p1_li2.18")}</p>
              <p>{t("t&c_p1_li2.19")}</p>
              <p>{t("t&c_p1_li2.20")}</p>
              <p>{t("t&c_p1_li2.21")}</p>
              <p>{t("t&c_p1_li2.22")}</p>
              <p>{t("t&c_p1_li2.23")}</p>
              <p>{t("t&c_p1_li2.24")}</p>
              <p>{t("t&c_p1_li2.25")}</p>
              <p>{t("t&c_p1_li2.26")}</p>
              <li>{t("t&c_p1_li3")}</li>
              <li>{t("t&c_p1_li4")}</li>
              <li>{t("t&c_p1_li5")}</li>
              <li>{t("t&c_p1_li6")}</li>
              <li>{t("t&c_p1_li7")}</li>
              <li>{t("t&c_p1_li8")}</li>
              <li>{t("t&c_p1_li9")}</li>
              <li>{t("t&c_p1_li10")}</li>
              <p>{t("t&c_p1_li10.1")}</p>
              <p>{t("t&c_p1_li10.2")}</p>
              <p>{t("t&c_p1_li10.3")}</p>
              <p>{t("t&c_p1_li10.4")}</p>
              <p>{t("t&c_p1_li10.5")}</p>
              <p>{t("t&c_p1_li10.6")}</p>
              <p>{t("t&c_p1_li10.7")}</p>
              <p>{t("t&c_p1_li10.8")}</p>
              <p>{t("t&c_p1_li10.9")}</p>
              <p>{t("t&c_p1_li10.10")}</p>
              <p>{t("t&c_p1_li10.11")}</p>

             </ul>
            
            </div>
       
          </Row>
        </Container>
      </div>
    </>
  );
};
export default TermsConditions;
