import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "../component/Home";
import CommonLayout from "../common/CommonLayout";
import Login from "../component/Login";
import ForgotPassword from "../component/ForgotPassword";
import SignUp from "../component/SignUp";
import ResetPassword from "../component/ResetPassword";
import CreateProfile from "../component/CreateProfile";
import ProductManagement from "../component/ProductManagement";
import Dashboard from "../component/pages/Dashboard";
import Notification from "../component/Notification";
import ProjectManagementView from "../component/pages/product-mangement/ProjectManagementView";
import AddProduct from "../component/pages/product-mangement/AddProduct";
import ProductInventory from "../component/pages/product-mangement/ProductInventory";
import ViewInventory from "../component/pages/product-mangement/ProductDetail";
import OrderManagementView from "../component/pages/order-management/OrderManagementView";
import OrderDetails from "../component/pages/order-management/OrderDetails";
import MyProfile from "../component/MyProfile";
import Review from "../component/Review";
import ContactUs from "../component/ContactUs";
import BankDetails from "../component/BankDetails";
import TransactionHistory from "../component/TransactionHistory";
import HarvestTracking from "../component/pages/product-mangement/HarvestTracking";
import TrackOrder from "../component/pages/order-management/TrackOrder";
import VerifyAccount from "../component/VerifyAccount";
import PrivacyPolicy from "../component/PrivacyPolicy";
import TermsConditions from "../component/TermsConditions";
import AboutUs from "../component/AboutUs";
import AboutUsSpanish from "../component/AboutUsSpanish";
import MobileLayout from "../common/MobileLayout";

const Routing = () => {
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={<CommonLayout>{<Home />}</CommonLayout>}
        ></Route>
        <Route
          path="/login"
          element={<CommonLayout><Login /></CommonLayout>}
        ></Route>
         <Route
          path="/forgot-password"
          element={<CommonLayout><ForgotPassword /></CommonLayout>}
        ></Route>
         <Route
          path="/signup"
          element={<CommonLayout><SignUp /></CommonLayout>}
        ></Route>
         <Route
          path="/privacy-policy"
          element={<CommonLayout><PrivacyPolicy /></CommonLayout>}
        ></Route>
         <Route
          path="/terms-condition"
          element={<CommonLayout><TermsConditions /></CommonLayout>}
        ></Route>
         <Route
          path="/privacy-policy/:lang"
          element={<MobileLayout><PrivacyPolicy /></MobileLayout>}
        ></Route>
         <Route
          path="/terms-condition/:lang"
          element={<MobileLayout><TermsConditions /></MobileLayout>}
        ></Route>
         <Route
          path="/reset-password/:token"
          element={<CommonLayout><ResetPassword /></CommonLayout>}
        ></Route>
         <Route
          path="/create-profile"
          element={<CommonLayout><CreateProfile /></CommonLayout>}
        ></Route> 
            <Route
          path="/update-profile"
          element={<CommonLayout><CreateProfile /></CommonLayout>}
        ></Route>
         <Route
          path="notifications"
          element={<CommonLayout><Notification /></CommonLayout>}
        ></Route>
         <Route
          path="verify-account/:status"
          element={<CommonLayout><VerifyAccount /></CommonLayout>}
        ></Route>
         <Route
          path="my-profile"
          element={<CommonLayout><MyProfile /></CommonLayout>}
        ></Route>
         <Route
          path="contact-us"
          element={<CommonLayout><ContactUs /></CommonLayout>}
        ></Route>
         <Route
          path="bank-details"
          element={<CommonLayout><BankDetails /></CommonLayout>}
        ></Route>
          <Route
          path="transaction-history"
          element={<CommonLayout><TransactionHistory /></CommonLayout>}
        ></Route>
          <Route
          path="reviews"
          element={<CommonLayout><Review /></CommonLayout>}
        ></Route>
         <Route
          path="/product-management"
          element={<CommonLayout><ProductManagement /></CommonLayout>}
        ></Route>
        <Route
          path="/dashboard"
          element={<CommonLayout><Dashboard /></CommonLayout>}
        ></Route>
         <Route
          path="product-mangement/products"
          element={<CommonLayout><ProjectManagementView /></CommonLayout>}
        ></Route>
        <Route
          path="product-mangement/add-product"
          element={<CommonLayout><AddProduct /></CommonLayout>}
        ></Route>
        <Route
          path="product-mangement/product-inventory"
          element={<CommonLayout><ProductInventory /></CommonLayout>}
        ></Route>
         <Route
          path="product-mangement/product-inventory/:productId/:productCultivatorId"
          element={<CommonLayout><ProductInventory /></CommonLayout>}
        ></Route>
        <Route
          path="product-mangement/view-inventory/:productId/:productCultivatorId"
          element={<CommonLayout><ViewInventory /></CommonLayout>}
        ></Route>
     
         <Route
          path="order-mangement/order-management-view"
          element={<CommonLayout><OrderManagementView /></CommonLayout>}
        ></Route>
         <Route
          path="order-mangement/order-details/:orderId"
          element={<CommonLayout><OrderDetails /></CommonLayout>}
        ></Route>
            <Route
          path="harvest-tracking"
          element={<CommonLayout><HarvestTracking /></CommonLayout>}
        ></Route>
          <Route
          path="order-mangement/track-order/:orderId"
          element={<CommonLayout><TrackOrder /></CommonLayout>}
        ></Route>
          <Route
          path="/about-us"
          element={<AboutUs />}
        ></Route>
        <Route
          path="/about-us-spanish"
          element={<AboutUsSpanish />}
        ></Route>
      </Routes>
    </Router>
  );
};

export default Routing;
