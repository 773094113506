import { Button, Col, Container, Form, Row } from "react-bootstrap";
const AboutUs = () => {
  return (
    <>
      <section className="height-footer about-outer-section about-us-spanish">
        {/* <div className="about-us-section">
          <Container>
            <h2>About Us</h2>
          </Container>
        </div> */}
        {/* about us content */}
        <Container>
          <Row className="mt-4">
            <Col md={12}>
              <h5 className="text-justify">Welcome to Semilla, where we bring the farm to your table! Our mission is to connect
                farmers directly with consumers, ensuring the freshest, highest quality produce while
                supporting local agriculture. We believe in the power of community and sustainability, and
                our platform is designed to make it easy for farmers, vendors, and drivers to thrive
                together.</h5>
            </Col>
          </Row>
        </Container>
        {/* process section starts */}
        <section className="process-us-section common-padding" id="how-it-works">
          <Container>
            <div className="choose-under-content">
              <div className="under-choose-box">
                <p className="text-justify">
                  At Semilla, we provide farmers with the tools they need to manage their inventory and
                  reach more customers, without disrupting their traditional sales routes. Our innovative
                  vendor tracking technology ensures seamless integration, allowing farmers to focus on
                  what they do best—growing fresh, delicious products.</p>
                <p className="text-justify mt-3">
                  For our customers, we offer the convenience of farm-fresh delivery, bringing a wide variety
                  of local produce straight to your doorstep. And for our drivers, we offer flexible job
                  opportunities that fit into your schedule, helping you earn money while supporting your
                  community.
                </p>
              </div>
              <img
                className=""
                src={require("../assets/images/process.png")}
                alt="img"
              />
              <div className="text-center">
              </div>
            </div>
          </Container>
        </section>
        {/* process section ends */}
        {/* newslater section starts */}
        <section className="newslater-section common-padding" id="contact-us">
          <Container>
            <Row className="justify-content-center">
              <Col lg={10} md={12}>
                <img
                  className="text-center d-block m-auto"
                  src={require("../assets/images/newslater.png")}
                  alt="img"
                />
                <div className="newslater-box">
                  <div className="subheading-section mb-4 text-center">
                    <h2 className="subheading">Join Us</h2>
                    <p className="text-justify">Join us in revolutionizing the way we access fresh food, support local farmers, and build a
                      more sustainable future. Together, we can make a difference—one delivery at a time.</p>
                  </div>
                  {/* <Form className="form-newslater">
                    <Form.Control type="email" placeholder="Enter Email Address" />
                    <Button variant="primary" type="submit" className="green-btn">
                      Subscribe
                    </Button>
                  </Form> */}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        {/* newslater section ends */}
      </section>
    </>
  );
};
export default AboutUs;
;
