import { useState, useEffect } from "react";
import {
  Alert,
  Button,
  Container,
  Dropdown,
  Modal,
  Nav,
  NavLink,
  Navbar,
} from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import ReactLanguageSelect from 'react-languages-select';
import 'react-languages-select/css/react-languages-select.css';
import { Link, useLocation } from "react-router-dom";
import { apiService } from "../service/api.service";
import swal from "sweetalert";
import { errorHandling, defaultLanguage, UtctolocalDate,socketUrl } from "../config/config";
import moment from "moment";

import 'moment/locale/es';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
import { Stomp } from '@stomp/stompjs';
import { useParams } from "react-router-dom";
const Header = () => {
  const [show, setShow] = useState(false);
  const [statusProfile, setstatusProfile] = useState(
    localStorage.getItem("profile_status")
  );
  console.log("======lang start reload==",localStorage.getItem("lang"))
  let props = useParams()


  let selectedlanguage = props.lang?props.lang:(localStorage.getItem("lang") && localStorage.getItem("lang")!="null"?localStorage.getItem("lang"):defaultLanguage)
  console.log("======lang start reload=selectedlanguage=",selectedlanguage)
 
  const { t } = useTranslation();
  const [profileCompleted, setprofileCompleted] = useState();
  const [notifyCount, setnotifyCount] = useState();
  const [notificationData, setnotificationData] = useState()
  const [total, settotal] = useState(0)
  
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const loginToken = localStorage.getItem("web_token");

  const location = useLocation();
  const profileImage = useSelector((state) => state.reducer.profileImage);
  useEffect(() => {
    moment.locale(localStorage.getItem("lang"));
    if (loginToken) {
      getNotification(0, 20)
      GetProfile();
    }
   
    // if(selectedlanguage!=="null" && localStorage.getItem("lang")){
    //   console.log( "iffff")
    //   i18n.changeLanguage(selectedlanguage);
    // }else{
    //  console.log( "elseee")
    //   selectedlanguage= defaultLanguage
    //   i18n.changeLanguage(selectedlanguage);
    // }
  
  }, []);
  useEffect(() => {
    // Set up WebSocket connection
    const client = Stomp.client(socketUrl);
    //
    client.connect({}, () => {
        // /subscribe/user/{identifier}'
        client.subscribe('/subscribe/user/' + localStorage.getItem("user"), (message) => {
          if (loginToken) {
            getNotification(0, 20)
            GetProfile();
          }
        });

        client.onStompError = (error) => {
            console.error('STOMP Error:', error);
        };

        // Optional: Add debug logging
        client.debug = (msg) => {
            console.log('STOMP Debug:', msg);
        };

        // Clean up function
        return () => {
            client.disconnect();
        };
    })


}, []);
  const logout = () => {
    localStorage.clear();
    if(selectedlanguage!=="null"){
    localStorage.setItem("lang",selectedlanguage)
    }else{
      localStorage.setItem("lang",defaultLanguage)
    }
    window.location.href = "/login";
  };
  const EditProfileData = () => {
    window.location.href = "/update-profile";
    setShow(false);
  };
  const varifyAccount = ()=>{
    apiService.stripLink().then((res) => {
      console.log(res.data.data.url)
      window.open(res.data.data.url, '_blank');
     }).catch((err) => { 
    });
  }
  const languageChange = (lng)=>{
    moment.locale(lng);
    apiService.changeLanguage(lng).then((res) => {
      console.log(res)
     console.log(window.location.pathname)
     if(window.location.pathname=="/terms-condition/es" || window.location.pathname=="/terms-condition/en"){
      window.location.href = "/terms-condition/"
     } else  if(window.location.pathname=="/privacy-policy/es" || window.location.pathname=="/privacy-policy/en"){
      window.location.href = "/privacy-policy/"
      //  window.location.reload()
     }else{
       window.location.reload()
     }
    
    }).catch((err) => { 
      // setLoader(false)
   
      // setupdateShow(true)
    });
  }
  const changeLanguage = (lng) => {
  console.log("lang====",lng)
    localStorage.setItem("lang",lng)
     console.log("lang====",localStorage.getItem("lang"))
    languageChange(lng)
    i18n.changeLanguage(lng);
  };
  
  const addBank = () => {

    // window.location.href = "/bank-details";
    window.open("https://stripe.com/connect-account/legal/full","_blank")
    setShow(false);
  };
  const addBankDetail = () => {
    window.location.href = "/bank-details";
   
    setShow(false);
  };
  const getNotification = (page, size) => {
    apiService.notifications(page, size).then((res) => {

      // setLoader(false)
      setnotificationData(res.data.data.list)
      console.log("======Get=noti data====>", res.data.data)
      settotal(res.data.data.total)
      // setbankList(res.data.data)

    }).catch((err) => {


      // setupdateShow(true)
    });
  }

  const GetProfile = () => {
    apiService
      .GetProfile()
      .then((res) => {
        console.log(res.data.data.user.status);
        setstatusProfile(res.data.data.user.status);
        // alert(res.data.data.user.name!==null)
        if (res.data.data.user.firstName && res.data.data.user.firstName != null) {
          setprofileCompleted(res.data.data.user.firstName);
        }
        setnotifyCount(res.data.data.user.notifyCount);
        
        localStorage.setItem("online_status", res.data.data.user.online);
        localStorage.setItem("lang", res.data.data.user.lang);
        localStorage.setItem("stripeBankId",res.data.data.user.payuBankAdded)
        localStorage.setItem("profile_status", res.data.data.user.status);
      })
      .catch((err) => {
        errorHandling(err.response.status);
        if (err.response.status === 401) {
            swal({ icon: 'error', text: "Your session has been expired, please login again. ", button: "OK" }).then(() => {
                localStorage.clear()
                window.location.href = "/login"
              });

        }
      });
  };
  return (
    <>
      <header>
        <Navbar expand="lg" className="">
          <Container>
            <Navbar.Brand
                 href={localStorage.getItem("web_token")   ? (location.pathname!=="/dashboard" && "/dashboard") :(location.pathname!=="/" &&"/")}
            >
              <img
                className="logo"
                src={require("../assets/images//logo.png")}
                alt="img"
              />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav className="ms-auto my-2 my-lg-0">
                <Nav.Link
                  href={localStorage.getItem("web_token")   ? (location.pathname!=="/dashboard" && "/dashboard") :(location.pathname!=="/" &&"/")}
                  className={location.pathname=="/dashboard" || location.pathname=="/" ? "active":""}
                >
                  {t('home')}
                </Nav.Link>
                {!loginToken &&
                <>
                <Nav.Link href="/#about-us" > {t('about_us')}</Nav.Link>
                <Nav.Link href="/#how-it-works"> {t('how_it_work')}</Nav.Link>
                </>
                }
                <Nav.Link href="/contact-us" className={location.pathname=="/contact-us" && "active"}> {t('contact_us')}</Nav.Link>
                {/* {loginToken && (
                  <Nav.Link href="/notifications" className="position-relative">
                    {notifyCount > 0 && (
                      <span className="dot-notification"></span>
                    )}
                    <img
                      className="desktop-view"
                      src={require("../assets/images/notification.svg").default}
                      alt="img"
                    />
                    <span className="mobile-view">Notifications</span>
                  </Nav.Link>
                )} */}
                {loginToken &&
                  <Nav.Link className="position-relative head-top-notifications" >
                    <Dropdown>
                      <Dropdown.Toggle variant="success" id="dropdown-basic">
                        {notifyCount > 0 && (
                          <span className="dot-notification"></span>
                        )}
                        <img
                          className="desktop-view"
                          src={
                            require("../assets/images/notification.svg").default
                          }
                          alt="img"
                        />
                        <span className="mobile-view">{t('notification')}</span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <div className="notification-content-top">
                          {notificationData && notificationData.map((data, index) => {
                            let orderData = JSON.parse(data.extras)
                            return (<Dropdown.Item>
                               <div onClick={() => { window.location.href = "/order-mangement/order-details/" + orderData.orderId }}  >
                                {data.description}
                                <p>{moment(UtctolocalDate(data?.time)).format("hh:mm a, DD MMM YYYY")}</p>
                              </div>

                            </Dropdown.Item>
                            )
                          })}
                          {notificationData && notificationData.length == 0 &&
                              <div className="nodata centre">
                                <img className="" src={require("../assets/images/no-data-found.png")} alt="img"
                                />
                                <span>{t("no_data")}</span>

                              </div>

                          }



                        </div>
                        { total > 20 &&

                          <a href="" onClick={() => { window.location.href =  "/notifications" }} className="notifications-view">{t("view_more")}</a>}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Link>}

                {!loginToken && (
                  <Nav.Link href="/login" className="join-us-now-btn">
                    {t('join_us')}
                    
                  </Nav.Link>
                )}


                {loginToken && (
                  <Nav.Link href="#action2">
                    <Dropdown>
                      <Dropdown.Toggle variant="unset" id="dropdown-basic">
                        {profileImage || localStorage.getItem("profile_img") ? (
                          <img
                            className="default-profile-img"
                            src={
                              profileImage
                                ? profileImage
                                : localStorage.getItem("profile_img")
                            }
                            alt="img"
                          />
                        ) : (
                          <img
                            className="default-profile-img"
                            src={require("../assets/images/user-default.png")}
                            alt="img"
                          />
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          href={
                            location.pathname == "/my-profile"
                              ? "javascript:void(0)"
                              : "/my-profile"
                          }
                        >
                         {t('my_profile')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href={
                            location.pathname == "/bank-details"
                              ? "javascript:void(0)"
                              : "/bank-details"
                          }
                        >
                         {t('bank_detail')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          href="https://semillarepartidor.online/"
                          target="_blank"
                        >
                         {t('uniform_link')}
                        </Dropdown.Item>
                        
                        <Dropdown.Item onClick={logout}>   {t('logout')}</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Link>
                )}

                <ReactLanguageSelect onSelect ={(e)=>changeLanguage(e)} defaultLanguage={selectedlanguage=="null"?defaultLanguage:selectedlanguage} languages={["en", "es"]} customLabels={{"en": "English", "es": "Spanish"}} placeholder="Change Language"  className="select-language" />

             
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    
      <Container>
        {statusProfile == "Incomplete_Profile" && !profileCompleted && (
          <Alert variant="warning" onClose={() => setShow(false)} dismissible>
             <p className="p-0 m-0">
              <i class="fa-solid fa-circle-exclamation"></i><b> {t('alert')}:</b>
              {t('profile_alert')}
            </p>
          </Alert>
        )}
        {statusProfile == "Pending" && profileCompleted && (
          <Alert variant="warning" onClose={() => setShow(false)} dismissible>
            <p className="p-0 m-0">
              <i class="fa-solid fa-circle-exclamation"></i> <b>{t('alert')}</b>
              {t('cannot_add')}
            </p>
          </Alert>
        )}

{statusProfile == "Incomplete_Profile" && profileCompleted && (
          <Alert variant="warning" onClose={() => setShow(false)} dismissible>
            <p className="p-0 m-0">
              <i class="fa-solid fa-circle-exclamation"></i><b> {t('alert')}:</b>
            {t('incomplete_profile_alert')}<span onClick={addBankDetail} className="edit_btn"> {t('click_here_add')}</span> 
            </p>
          </Alert>
        )}
{statusProfile == "Stripe_Details_Pending" && profileCompleted && (
          <Alert variant="warning" onClose={() => setShow(false)} dismissible>
            <p className="p-0 m-0">
              <i class="fa-solid fa-circle-exclamation"></i><b>{t('alert')}:</b>
            {t('bank_alert')} <span onClick={varifyAccount} className="edit_btn"> {t('click_here_verify')}</span> 
            </p>
          </Alert>
        )}
{statusProfile == "Stripe_Verification_Pending" && profileCompleted && (
          <Alert variant="warning" onClose={() => setShow(false)} dismissible>
             <p className="p-0 m-0">
              <i class="fa-solid fa-circle-exclamation"></i><b> {t('alert')}:</b>
            {t('bank_process')}

            </p>
          </Alert>
        )}
        {statusProfile == "Reject" && (
          <Alert variant="success" onClose={() => setShow(false)} dismissible>
               <p className="p-0 m-0">
              <i class="fa-solid fa-circle-exclamation"></i><b>{t('alert')}</b>
            {t('profile_reject')} {" "}
              <span onClick={EditProfileData} className="edit_btn">
              {t('update_profile')}
               
              </span>
            </p>
          </Alert>
        )}
      </Container>
      {/* alert message start */}
      {/* logout modal */}
      <Modal
        show={show}
        onHide={handleClose}
        className="modal-success"
        centered>
        <Modal.Body>
          <div className="modal-success-body text-center">
            <img className="" src={require("../assets/images/logout-mark.svg").default}
              alt="img"
            />
            <p>{t('are_you_sure')}</p>
            <Button variant="primary" className="green-btn">
            {t('yes')}
            </Button>
            <Button variant="primary" className="green-btn green-outline-btn">
            {t('no')}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default Header;
