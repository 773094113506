import { useEffect, useState } from 'react';
import { Col, Container, Row, Form, Button, Modal } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { validateService } from "../service/validation.service";
import { apiService } from "../service/api.service";
import Loader from "../common/Loader";
import { allowedImageTypes, baseUrl, allowedDocTypes, gKey, errorHandling } from "../config/config"
import GooglePlacesAutocomplete, { geocodeByAddress, getLatLng, geocodeByLatLng } from 'react-google-places-autocomplete';
import { useSelector, useDispatch } from 'react-redux'
import { setprofileImage } from '../store/index_slice'
import swal from 'sweetalert';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
const CreateProfile = () => {
  const { t } = useTranslation();
  let history = useNavigate();
  function Dashboard() {
    history("/dashboard");
  }
  const dispatch = useDispatch()
  const location = useLocation()
  const [value, setValue] = useState(null);
  const [loader, setLoader] = useState(false)
  const [show, setShow] = useState(false);
  const [validFiles, setvalidFiles] = useState(true);
  const [image, setImage] = useState();
  const [backFile, setbackFile] = useState();
  const [frontFile, setfrontFile] = useState();
  const [frontFileView, setfrontFileView] = useState();
  const [backFileView, setbackFileView] = useState();
  const [imageShow, setimageShow] = useState();
  const [imageErr, setImageErr] = useState();
  const [backErr, setbackErr] = useState();
  const [frontErr, setfrontErr] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    if (location.pathname == "/update-profile") {
      GetProfile()
    }

  }, [])
  const handleError = () => {
    //  setValue()
  }
  const [profileFields, setprofileFields] = useState({
    firstName: "",
    lastName:"",
    phone: "",
    businessName: "",
    gender: "",
    yearOfExperience: "",
    typeOfCultivation: "",
    city: "",
    country: "",
    countryCode: "",
    longitude: 0,
    latitude: 0

  });
  const [profileFieldsErrs, setprofileFieldsErrs] = useState({
    firstName: "",
    lastName:"",
    phone: "",
    businessName: "",
    gender: "",
    yearOfExperience: "",
    typeOfCultivation: "",
    address: "",
    doc: "",
    city: "",
    country: "",
  });

  const handleOnChange = (value, country) => {
    setprofileFields({
      ...profileFields,
      phone: "+"+value,
      countryCode:"+"+country.dialCode
    });
    // setDialCode(country.dialCode);
  };
  const onInputChange = (field, value) => {
    console.log(profileFields)
    if (field == "name") {
      let validtext = CheckText(value)
      if (validtext || value == "") {
        setprofileFields({
          ...profileFields,
          [field]: value,
        });
      }
    } else {
      setprofileFields({
        ...profileFields,
        [field]: value,
      });
    }


  };
  const CheckText = (name) => {
    var regex = /^[a-zA-Z\s]+$/;
    // This is will test the value against the regex
    // Will return True if regex satisfied
    return regex.test(name)



  }

  useEffect(() => {
    if (value) {
      geocodeByAddress(value.label)
        .then(results => getLatLng(results[0]))
        .then(async ({ lat, lng }) => {
          const data = await reverseGeocode(lat, lng);

          console.log(data, "===data==")
          setprofileFields({
            ...profileFields,
            address: value.label,
            latitude: lat,
            longitude: lng,
            city: data.city,
            country: data.country

          })

        }

        );
    }
  }, [value]);
  const getData = async (lati, lngi, addressadded) => {
    console.log( lati,lngi,addressadded, "=====")
    try {
    await geocodeByLatLng({ lat: lati, lng: lngi }).then(
      (response) => {
        console.log(response, "=====")
        const address = response[0].formatted_address;
        console.log(address, "====addressaddressaddressaddress=")
        setValue({ label: addressadded, value: response[0] })

      })
    } catch (error) {
      GetProfile()
      console.log('Error fetching address: ' + error.message);
    }
  }
  const GetProfile = () => {
    apiService.GetProfile().then((res) => {
      let data = {
        name: "",
        phone: "",
        age: "",
        gender: "",
        businessName: "",
        yearOfExperience: "",
        typeOfCultivation: "",
        city: "",
        country: "",
        longitude: "",
        latitude: ""
      }
      console.log(res.data)
      if (res.data.data.user.imagePath) {

        setimageShow(baseUrl + res.data.data.user.imagePath)
      }

      data.id = res.data.data.user.id
      data.businessName = res.data.data.user.cultivator.businessName
      data.gender = res.data.data.user.gender
      data.firstName = res.data.data.user.firstName
      data.lastName = res.data.data.user.lastName
      data.phone = res.data.data.user.phone
      data.address = res.data.data.user.address

      if (res.data.data.user.latitude != "" && res.data.data.user.latitude != null) {
        getData(res.data.data.user.latitude, res.data.data.user.longitude, res.data.data.user.address)
      }
      data.city = res.data.data.user.cultivator.city
      data.country = res.data.data.user.cultivator.country
      data.typeOfCultivation = res.data.data.user.cultivator.typeOfCultivation
      data.yearOfExperience = res.data.data.user.cultivator.yearOfExperience
      if (res.data.data.user.cultivator.idProofFront != null) {
        setfrontFileView(baseUrl + res.data.data.user.cultivator.idProofFront)
      }
      setprofileFields(data)
      if (res.data.data.user.cultivator.idProofBack != null) {
        setbackFileView(baseUrl + res.data.data.user.cultivator.idProofBack)
      }


    }).catch((err) => {
      setLoader(false)
      errorHandling(err.response?.status)
    console.log("contact us api error", err.response);
    });
  }
  const handleKeyDownnumber = (event) => {
    console.log("=====event====", event.which);
    if (event.which === 32) {
      event.preventDefault(); // Prevent space key action
    }
    if (event.key === "Enter" || event.which === 13) {
      event.preventDefault(); // Prevent the default behavior (page refresh)
      // Handle your logic here
    }
  };
  const handleSubmit = async () => {
  
    let valid = validateService.validateProfile(setprofileFieldsErrs, profileFields, frontFileView? frontFileView : frontFile,backFileView? backFileView : backFile,setfrontErr,setbackErr)
    
    if (valid && validFiles) {
      setLoader(true)
      apiService.CreateProfile(profileFields, image).then(async (res) => {
        if (image) {
          dispatch(setprofileImage(baseUrl + res.data.data.user.imagePath))
          localStorage.setItem("profile_img", baseUrl + res.data.data.user.imagePath)
        }
        console.log(res.data.data.user.imagePath)
        setprofileFields({
          firstName: "",
          lastName: "",
          phone: "",
          businessName: "",
          age: "",
          gender: "",
          yearOfExperience: "",
          typeOfCultivation: "",
          city: "",
          country: "",
          address: "",
          longitude: 0,
          latitude: 0
        });
        setprofileFieldsErrs({
          name: "",
          phone: "",
          businessName: "",
          age: "",
          gender: "",
          yearOfExperience: "",
          typeOfCultivation: "",
          docErr: "",
          city: "",
          location: "",
          country: "",

        });


        if (frontFile) {
          await uploadFrontFile("idProofFront", frontFile)
        } else {
          if (backFile) {
            await uploadBackFile("idProofBack", backFile)
          } else {
            setLoader(false)
            window.location.href = "/my-profile"
            history("/my-profile");
          }

        }


      }).catch((err) => {
        errorHandling(err.response.status)
        //   if (err.response.status === 401) {
        //     swal({ icon: 'error', text: "Your session has been expired, please login again. ", button: "OK" }).then(() => {
        //         localStorage.clear()
        //         window.location.href = "/login"   
        //       });

        // }
        setLoader(false)
        console.log(err)
        console.log("contact us api error", err.response);
      });
    }
  }
  const uploadBackFile = (type, docFile) => {

    if (docFile) {
      setLoader(true)
      let dataDoc = new FormData()
      dataDoc.append("file", docFile)
      apiService.uplodFile(type, dataDoc).then((res) => {
         window.location.href = "/my-profile"
         setLoader(false)
      }).catch((err) => {
        errorHandling(err.response.status)
        setLoader(false)
      });
    }
  }
  const uploadFrontFile = (type, frontFile) => {
    if (frontFile) {
      setLoader(true)
      let dataDoc = new FormData()
      dataDoc.append("file", frontFile)
      apiService.uplodFile(type, dataDoc).then(async (res) => {
        if (backFile) {
          await uploadBackFile("idProofBack", backFile)

        } else {
          setLoader(false)
          window.location.href = "/my-profile"
        }
        // 

      }).catch((err) => {
        errorHandling(err.response.status)
        setLoader(false)
        console.log(err)
        console.log("contact us api error", err.response);
      });
    }
  }
 

  const handleVerifyFrontChange = (file) => {
    const selectedImage = file;
    let valid = true
    if (selectedImage) {

      if (allowedImageTypes.includes(selectedImage.type)) {
        setfrontFile(selectedImage)
        setvalidFiles(true)
        valid = true
        const reader = new FileReader();
        reader.onload = () => {
          setfrontFileView(reader.result);
        };
        reader.readAsDataURL(selectedImage);
        setfrontErr("")
      } else {
        setvalidFiles(false)
        valid = false
       
        setfrontErr(t("imges_valid"))

      }
    }
    return valid
  }
  const handleVerifyBackChange = (file) => {
    const selectedImage = file;
    console.log("=====......>>>")
    let valid = true
    if (selectedImage) {

      if (allowedImageTypes.includes(selectedImage.type)) {
        setbackFile(selectedImage)
        valid = true
        setvalidFiles(true)
        const reader = new FileReader();
        reader.onload = () => {
          setbackFileView(reader.result);
        };
        reader.readAsDataURL(selectedImage);
        setbackErr("")
      }else{
        setvalidFiles(false)
        valid = false
        console.log("=====......")
        setbackErr(t("imges_valid"))
        // setbackErr("Invalid file type. Please select a JPEG,JPG or PNG image.")
     }
    }
    return valid
  }
  const handleImageChange = (file) => {

    const selectedImage = file;
    let valid = true
    if (selectedImage) {

      if (allowedImageTypes.includes(selectedImage.type)) {
        setImage(selectedImage)
        valid = true
        setvalidFiles(true)
        const reader = new FileReader();
        reader.onload = () => {
          setimageShow(reader.result);
        };
        reader.readAsDataURL(selectedImage);
        setImageErr("")
      } else {
        valid = false
        setvalidFiles(false)
        setImageErr(t("imges_valid"))

      }
    }
    return valid
  };
  async function reverseGeocode(latitude, longitude) {
    try {
      const response = await fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
      const data = await response.json();
      const address = data.address;
      const country = address.country;
      const city = address.city || address.town || address.village || address.hamlet || address.suburb || address.locality;
      return { country, city };
    } catch (error) {
      console.error('Error retrieving geolocation data:', error);
      return { error: 'Error retrieving geolocation data' };
    }
  }
  return (
    <>
      <div className="height-footer">
        <section className="create-profile-section common-padding">
          {loader && <Loader />}
          <Container>
            <Row className="justify-content-center">
              <Col lg={11} md={12}>
                <div className="login-form-box">
                  <div className="login-header">
                    <h2>{location.pathname == "/update-profile" ? t("update-profile") : t("create_profile")} </h2>
                  </div>
                  <Form className="login-body">
                    <Row>
                      <Col md={12}>
                        <div className="profile-upload position-relative text-center">
                          <Form.Control type="file" onChange={(e) => handleImageChange(e.target.files[0])} />
                          <img
                            className=""
                            src={imageShow ? imageShow : require("../assets/images/user-default.png")}
                            alt="img"
                          />
                          <p className="m-0">{t("upload_profile")}</p>
                          <span className="text-danger">{imageErr}</span>
                        </div>

                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("first_name")}</Form.Label>
                          <Form.Control maxLength={50} type="text" placeholder={t("enter_fname")} value={profileFields.firstName} onChange={(e) => onInputChange("firstName", e.target.value)} />
                          <span className="text-danger">{t(profileFieldsErrs.firstName)}</span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("last_name")}</Form.Label>
                          <Form.Control maxLength={50} type="text" placeholder={t("enter_lname")} value={profileFields.lastName} onChange={(e) => onInputChange("lastName", e.target.value)} />
                          <span className="text-danger">{t(profileFieldsErrs.lastName)}</span>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("phone_number")}</Form.Label>
                          <PhoneInput
                            value={profileFields.phone}
                            onlyCountries={['co', 'ar', // Argentina
                            'bo', // Bolivia
                            'br', // Brazil
                            'cl', // Chile
                            'co', // Colombia
                            'ec', // Ecuador
                            'pe', // Peru
                            'uy', // Uruguay
                            've', // Venezuela
                            'us',
                            'ca',
                          ]}
                          onChange={handleOnChange}
                            country={"co"}
                            className={"phone-input"}
                            placeholder={t("phone_number")}

                            // onChange={(e) => onInputChange("phone", e)}
                          />
                          <span className="text-danger">{t(profileFieldsErrs.phone)}</span>
                          {/* <Form.Control type="text" placeholder="Enter phone number" /> */}
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("business_name")}</Form.Label>
                          <Form.Control
                            maxLength={50}
                            // onWheel={() => document.activeElement.blur()}
                            type="text"
                            placeholder={t("business")}
                            value={profileFields.businessName}
                            onChange={(e) => {

                              onInputChange("businessName", e.target.value);

                            }}
                          />
                          <span className="text-danger">{t(profileFieldsErrs.businessName)}</span>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={3}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("gender")}</Form.Label>
                          <Form.Select aria-label="Default select example" onChange={(e) => onInputChange("gender", e.target.value)} value={profileFields.gender}>
                            <option>{t("select_gender")}</option>
                            <option value="Male">{t("male")}</option>
                            <option value="Female">{t("female")}</option>
                            <option value="Other">{t("other")}</option>
                          </Form.Select>
                          <span className="text-danger">{t(profileFieldsErrs.gender)}</span>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={3}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("experience")}</Form.Label>
                          <Form.Control
                            onKeyDown={handleKeyDownnumber}
                            maxLength={3}
                            onWheel={() => document.activeElement.blur()}
                            type="number"
                            placeholder={t("enter_exp")}
                            value={profileFields.yearOfExperience}
                            onChange={(e) => {
                              if (e.target.value.length <= 3) {
                                onInputChange("yearOfExperience", e.target.value);
                              } else {
                                return false;
                              }
                            }}
                          />
                          <span className="text-danger">{t(profileFieldsErrs.yearOfExperience)}</span>
                          {/* <Form.Control type="text" placeholder="Enter Age" /> */}
                        </Form.Group>
                      </Col>

               <Col md={6} lg={6}>
                        <Form.Group className="mb-4 google-location">
                          <Form.Label>{t("location")}</Form.Label>
                          <GooglePlacesAutocomplete
                            apiKey={gKey}
                            className="form-control google-location"
                            onError={() => handleError()}
                            onFail={error => console.log(error)}
                            onNotFound={() => console.log('no results')}
                            defaultShow={false}
                            selectProps={{
                              placeholder:t("enter_location"),
                              value,
                              onChange: setValue,
                             }}
                            value={profileFields.address}
                          />
                        <span className="text-danger">{t(profileFieldsErrs.location)}</span>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={6}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("cultivation_type")}</Form.Label>
                          <Form.Control type="text" placeholder={t("culti_place")} onChange={(e) => onInputChange("typeOfCultivation", e.target.value)}
                            value={profileFields.typeOfCultivation} />
                          <span className="text-danger">{t(profileFieldsErrs.typeOfCultivation)}</span>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={3}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("city")}</Form.Label>
                          <Form.Control type="text" placeholder={t("enter_city")} onChange={(e) => onInputChange("city", e.target.value)} value={profileFields.city} />
                          <span className="text-danger">{t(profileFieldsErrs.city)}</span>
                        </Form.Group>
                      </Col>
                      <Col md={6} lg={3}>
                        <Form.Group className="mb-4">
                          <Form.Label>{t("country")}</Form.Label>
                          <Form.Control type="text" placeholder={t("enter_country")} onChange={(e) => onInputChange("country", e.target.value)} value={profileFields.country} />
                          <span className="text-danger">{t(profileFieldsErrs.country)}</span>
                        </Form.Group>
                      </Col>
                      <Col md={6}>
                        <Form.Label>{t("id_proof")}</Form.Label>
                        <div className="profile-id-bottom">
                          <div className="id-front me-3">
                            <div className=''>
                              <div className='upload-document position-relative id-proofs-section'>
                                <div>
                                  <Form.Control type="file"  onChange={(e) => handleVerifyFrontChange(e.target.files[0])}/>
                                  <img
                                    className="file-upload"
                                    src={frontFileView?frontFileView:require("../assets/images/upload.svg").default}
                                    alt="img"
                                  />
                                </div>
                             
                              </div>
                             
                              <h5 className='upload-text'>{t("upload_front")} <span>{t("upload_front1")}</span></h5>
                              <span className='errors'>{t(frontErr)}</span>
                            </div>
                          </div>
                          <div className="id-front">
                            <div className=''>
                              <div className='upload-document position-relative id-proofs-section'>
                                <div>
                                  <Form.Control type="file" onChange={(e) => handleVerifyBackChange(e.target.files[0])}/>
                                  <img
                                    className="file-upload"
                                    src={backFileView?backFileView:require("../assets/images/upload.svg").default}
                                    alt="img"
                                  />
                                </div>
                               
                              </div>
                              <h5 className='upload-text'>{t("upload_back")} <span>{t("upload_back1")}</span></h5>
                              <span className='errors'>{t(backErr)}</span>
                            
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <div className="action-btn text-center">
                      <Button className="green-btn" onClick={handleSubmit}>{t("submit")} </Button>
                    </div>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </div>
      {/* success modal */}
      <Modal show={show} onHide={handleClose} className='modal-success' backdrop="static" centered>
        <Modal.Body>
          <div className='modal-success-body text-center'>
            <img
              className=""
              src={require("../assets/images/check-mark.svg").default}
              alt="img"
            />
            <h3>{("success")}!</h3>
            <p>{t("profile_success")}</p>
            <Button variant="primary" className="green-btn" onClick={Dashboard} >
            {("ok")}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CreateProfile;
