import { Button, Col, Container, Row } from "react-bootstrap";
import { useNavigate,useParams} from "react-router-dom";

const VerifyAccount = () => {
  let props = useParams()
  const GotoHome =()=>{
    window.location.href = "/"
  }
  return (
    <>
      <div className="height-footer verfiy-account-section">
        <Container>
          <Row className="justify-content-center">
            <Col md={6}>
              {/* could not verify your account details section start */}
              <div className="verify-content-box text-center">
                {props.status=="reject" &&
                <img src={require("../assets/images/oops-svg.svg").default} /> }
                <>
                 {props.status=="success" &&
                <img src={require("../assets/images/submit-successfully.png")} />}
                </>
              {props.status=="reject" && <h5 className="mt-3">We could not verify your account details! </h5> }
              {props.status=="success" && <h5 className="mt-3"> Your account details has been submitted successfully! </h5>}
            {props.status=="reject" && <h6 className="mt-3">Please verify your account details to receive payouts.</h6> }
            {props.status=="success" && <h6 className="mt-3">We will keep you informed about any updates regarding your account.</h6>}
             <Button className="join-us-now-btn mt-4" onClick={()=> {GotoHome()}}>Go to Home</Button>
              </div>
              {/* could not verify your account details section ends */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};
export default VerifyAccount;
