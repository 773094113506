import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import '../i18n';
const Footer = () => {
  const { t } = useTranslation();
  return <>
    <footer>
      <div className="footer-header">
        <Container>
          <div className="footer-contents pb-3">
            <img
              className="text-center d-block m-auto"
              src={require("../assets/images/footer-logo.png")}
              alt="img"
            />
            <ul>
              <li>
                <a href="/contact-us">{t("contact_us")} </a>
              </li>
              <li>
                <a href="/terms-condition">{t("t&c")}</a>
              </li>
              <li>
                <a href="/privacy-policy">{t("privacy_policy")}</a>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      <div className="footer-body py-3">
        <Container>
          <div className="copy-right-section">
            <p className="p-0 m-0">© Copyright 2024 by semilla</p>
            <div className="social-media">
              <ul>
                <li>
                  <a href="https://www.facebook.com/SemillaApp/" target="_blank"> <img
                    className="text-center d-block m-auto"
                    src={require("../assets/images/Social Media1.svg").default}
                    alt="img"
                  /></a>
                </li>



 


                <li>
                  <a href="https://www.instagram.com/SemillaApp/ " target="_blank"> <img
                    className="text-center d-block m-auto"
                    src={require("../assets/images/Social Media2.svg").default}
                    alt="img"
                  /></a>
                </li>
                <li>
                  <a href="https://www.linkedin.com/SemillaApp" target="_blank"> <img
                    className="text-center d-block m-auto"
                    src={require("../assets/images/Social Media3.svg").default}
                    alt="img"
                  /></a>
                </li>
              </ul>
            </div>
          </div>
        </Container>
      </div>
    </footer>
  </>;
};
export default Footer;
