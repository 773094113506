import { configureStore } from '@reduxjs/toolkit'
import {indexSlice} from './index_slice'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from '@reduxjs/toolkit'
import persistReducer from 'redux-persist/es/persistReducer'
let rootReducer=combineReducers({
    reducer: indexSlice.reducer,
})
let persistConfig = {
    key:'root',
    storage,
}
let reducerPersist = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
    reducer: reducerPersist,
})