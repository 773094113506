// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './locales/en.json';
import esTranslation from './locales/es.json';
import {defaultLanguage} from './config/config';
console.log("======lang start reload==",localStorage.getItem("lang"),defaultLanguage)
console.log("======lang start reload==condition",localStorage.getItem("lang")!="null"? localStorage.getItem("lang"):defaultLanguage)

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: enTranslation,
      },
      es: {
        translation: esTranslation,
      },
    },
    lng: localStorage.getItem("lang") && localStorage.getItem("lang")!="null"? localStorage.getItem("lang"):defaultLanguage, // default language
    fallbackLng: localStorage.getItem("lang") && localStorage.getItem("lang")!="null"? localStorage.getItem("lang"):defaultLanguage, // fallback language
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
