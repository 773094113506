import { Col, Container, Row, Form, Button } from "react-bootstrap";
import { apiService} from "../service/api.service";
import { useEffect, useState } from 'react';
import Pagination from "react-js-pagination";
import { UtctolocalDate,baseUrl} from "../config/config"
import ReactStars from "react-rating-stars-component";
import moment from "moment";
import Loader from "../common/Loader";
const Review = () => {

  const [loader,setLoader]=useState(false)
  const [page,setPage]=useState(0)
  const [size,setSize]=useState(9)
  const [allReviews,setallReviews]=useState()
  const [totalItems,settotalItems]=useState(0)
  useEffect(() => {
    allReviewsData(page, size)
}, [])
  const allReviewsData =(page,size)=>{
    setLoader(true)
    apiService.allReviews(page,size).then((res) => {
      console.log(res.data.data)
      settotalItems(res.data.data.total)
      setallReviews(res.data.data.list)
      setLoader(false)
    
 
        // setbankList(res.data.data)
  
    }).catch((err) => { 
      setLoader(false)
   
      // setupdateShow(true)
    });
  }
  const getTimeAgo = (dateTime) => {
    dateTime =  moment.utc(dateTime).local()
    return moment(dateTime).fromNow();
  };

  function handlePageChange(pageNumber) {
    setPage(pageNumber - 1);
    allReviewsData(pageNumber - 1, size)
}
  return (
    <>
      <div className="height-footer review-section">
      {loader && <Loader/>}
        <Container>
          <div className='product-header-area'>
            <h2 className='subheading-under'>Our Customers Reviews</h2>
          </div>
          <Row>
             {allReviews && allReviews.map((data,i)=>{
             let ratingStars = {
                size: 30,
                value: data.rating,
                edit: false,
                isHalf: true,
                emptyIcon: <i className="far fa-star" />,
                halfIcon: <i className="fa fa-star-half-alt" />,
                filledIcon: <i className="fa fa-star" />,
            }
              return( <Col lg={4}>
               <div className="customer-box">
                 <div className="customer-feedback">
                   <p className="customer-id text-right">{getTimeAgo(data.createdDate)} </p>
                   <div className="customer-fedback-img">
                     <div>
              {data?.user.imagePath == null ?

                  <img
                      className=""
                      src={require("../assets/images/user-default.png")}
                      alt="img"
                  /> :
                  <img
                      className=""
                      src={baseUrl + data?.user.imagePath}
                      alt="img"
                  />} 
                     </div>
                     <div>
                       <p className="ratings mb-3 mt-0"><span className="star-pattern"> <ReactStars {...ratingStars} /> </span> ({data.rating})</p>
                       <h5 className="review-heading">{data?.user.firstName+" "+data?.user.lastName}</h5>
                       <p className="customer-id mt-1">{data?.description}</p>
                     </div>
                   </div>
                 </div>
               </div>
             </Col>)

             })}
           
           

          </Row>
         
          {allReviews && allReviews.length > 0 && totalItems > size ? (
                            <div className="text-right">
                            <div className='pagination-tab'>
                                <Pagination
                                    activePage={page + 1}
                                    itemsCountPerPage={size}
                                    totalItemsCount={totalItems}
                                    pageRangeDisplayed={5}
                                    onChange={(e) => handlePageChange(e)}
                                // prevPageText={"Prev"}
                                // nextPageText={"Next"}
                                />
                            </div>
                            </div>
                        ) : null}
        </Container>
      </div>
    </>
  );
};
export default Review;
